import React, { useContext, useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import '../styles/index.scss'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import queryString from 'query-string'
import { DEFAULT_MAX_CONTENT_WIDTH } from './settings/globals'
//Settings
import { SEARCH_URL, SEARCH_TIMEOUT } from './settings/globals'
//Context
import SearchContext from '../contexts/SearchContext'
//Components
import { Alert } from './Alert/Alert'
import CatalogFilters from './form/filters/CatalogFilters'
import CatalogSort from './form/CatalogSort'
import ErrorPage from './Pages/ErrorPage/ErrorPage'
import EmptySearchFeedback from './EmptySearchFeedback'
import { ExportButton } from './Button/variants/ExportButton'
import { FilterButton } from './Button/variants/FilterButton'
import { SelectOptionsButton } from './Button/variants/SelectOptionsButton'
import NoResultsFeedback from './NoResultsFeedback'
import Pagination from './utilities/Pagination'
import ResultsPerPage from './form/ResultsPerPage'
import { ButtonSharePage } from './Button/variants/ButtonSharePage'
import Summary from './Templates/Results/results-summary'
import { Results } from './Templates/Results/Results'
import { ResultsLoader } from './ContentLoaders/ResultsLoader'
//Helpers
import {
  useSafeAsync,
  returnSortRequest,
} from '../helper-functions/query-functions'
import { returnAbbreviatedQueryStringFromObject } from '../helper-functions/param-functions'
import useScreenSize from '../hooks/use-screen-size'
import { ButtonDisplayOptions } from './Button/variants/ButtonDisplayOptions'
import ExportContext from '../contexts/ExportContext'
import { returnNumberWithCommas } from '../helper-functions/number-functions'

const SearchResults = ({ ...props }) => {
  const { setActiveQueryParams, setActiveResults, setTotal, total } =
    useContext(SearchContext)

  const navigate = useNavigate()
  const safeAsync = useSafeAsync()

  const { search, state } = useLocation()
  const q = queryString.parse(search)

  const { screenSize } = useScreenSize()

  const [query, setQuery] = useState(q || null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [results, setResults] = useState(null)
  const [pages, setPages] = useState(null)
  const [filters, setFilters] = useState(null)
  const [time, setTime] = useState('')
  const [timedOut, setTimedOut] = useState(false)
  const [page, setPage] = useState(DOMPurify.sanitize(parseInt(q.page)) || 1)
  const [limit, setLimit] = useState(
    DOMPurify.sanitize(parseInt(q.limit)) || 20
  )
  const [sort, setSort] = useState(
    returnSortRequest(DOMPurify.sanitize(q.sort)) || ''
  )
  const [exportData, setExportData] = useState([{}])

  useEffect(() => {
    setActiveQueryParams(q)
  }, [search])

  const handleSetResults = (params) => {
    const sent = new Date().getTime()

    safeAsync(
      // Fetch data
      fetch(
        `${SEARCH_URL}/records/search?${returnAbbreviatedQueryStringFromObject(
          params
        )}&datesAgg=TRUE`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        // Throw API-returned errors
        .then((response) => {
          if (!response.ok) {
            throw response
          }
          return response.json()
        })
        // Handle response data
        .then((data) => {
          const count = data.body.hits.total.value
          const limit = params.limit || 20
          const page = params.page || 1
          const received = new Date().getTime()
          // Elasticsearch timeout & pagination error
          data.body.timed_out == true ? setTimedOut(true) : setTimedOut(false)
          if (timedOut && count == 0) throw 'NO_RESULTS_TIMED_OUT'
          if (page * limit - limit > count) throw 'NO_MORE_RESULTS'
          else {
            let results = []
            data?.body?.hits.hits.map((result) => {
              let record = { ...result._source.record, ...result.fields }
              results.push(record)
            })
            setResults(results)
            setExportData(results)

            delete data?.body?.aggregations?.digitalObjectCount
            setFilters(data.body?.aggregations)
            setTime(((received - sent) / 1000.0).toFixed(2))
            setTotal(count)
            setActiveResults(results)
            setPages(Math.ceil(count / limit))
          }
          setLoading(false)
        })
        // Error handling - if the error originates from the server, set error to server response
        // If the error comes from the front end (here), manually setError with extra details
        .catch((error) => {
          setLoading(false)
          if (typeof error.text === 'function') {
            error.text().then((errorMessage) => {
              setError(JSON.parse(errorMessage))
            })
          } else if (error == 'NO_MORE_RESULTS') {
            setError({
              title: 'Results not found',
              status: 404,
              description:
                'There are no more results available for this search.',
              id: 'NO_RESULTS',
            })
          } else if (error == 'NO_RESULTS_TIMED_OUT') {
            setError({
              title: 'Timed out with no results.',
              status: 408,
              description:
                'This search took too long, and no results were found.',
              id: 'NO_RESULTS_TIMED_OUT',
            })
          } else {
            console.error('Error fetching data: ', error)
            setError(error)
          }
        })
    )
  }

  useEffect(() => {
    if (loading) return false
    window.scrollTo(0, 0)
  }, [loading])

  useEffect(() => {
    let params = queryString.parse(search)
    let page = DOMPurify.sanitize(parseInt(params.page)) || 1
    let limit = DOMPurify.sanitize(parseInt(params.limit)) || 20
    setQuery(params)
    setPage(page)
    setLimit(limit)
    setSort(DOMPurify.sanitize(params.sort))
    setResults(null)
    setError(null)
    setLoading(true)
    handleSetResults(params)
  }, [search])

  const updateURLParam = (paramName, value, resetPage = false) => {
    let pushQuery = { ...q }

    if (value === '' || !value) {
      delete pushQuery[paramName]
    } else {
      if (paramName === 'dates') {
        const [startDate, endDate] = value
        pushQuery['startDate'] = startDate
        pushQuery['endDate'] = endDate
      } else {
        pushQuery[paramName] = value
      }
    }

    navigate({
      pathname: `/search`,
      search: `?${queryString.stringify(pushQuery)}`,
    })
  }

  /* PAGINATION FUNCTIONS
   * For use with the Pagination component.
   * Defines functionality when the next/prev pagination buttons are clicked,
   * * or an input is entered into the pagination input field.
   * */
  const resetPage = () => {
    updateURLParam('page', 1, true)
  }
  const updatePage = (page) => {
    if (page < 0) return false
    if (page > pages) {
      updateURLParam('page', pages)
      setPage(parseInt(pages))
    } else setPage(parseInt(page))
    if (page == 1) resetPage()
    else updateURLParam('page', page)
  }
  const nextPage = () => {
    if (parseInt(page) + 1 > pages) return false
    if (parseInt(page) < pages) {
      setPage(parseInt(page) + 1)
      updateURLParam('page', parseInt(page) + 1)
    }
  }
  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1)
      if (page - 1 == 1) resetPage()
      else updateURLParam('page', page - 1)
    }
  }
  const handleUpdateLimit = (value) => {
    setLimit(value)
    const reset = page * value - value > total
    updateURLParam('limit', value, reset)
  }
  const handleUpdateSort = (value) => {
    setSort(returnSortRequest(DOMPurify.sanitize(value)))
    if (value == 'relevant') updateURLParam('sort', '')
    else updateURLParam('sort', value)
  }
  const handleUpdateFilters = (param, value, remove = false) => {
    setPage(1)
    if (remove) value = ''
    if (param === 'dates') {
      if (value.indexOf('-') > -1) {
        const dates = value.split('-')
        updateURLParam(param, dates, true)
      }
    } else {
      updateURLParam(param, value, true)
    }
  }

  const emptySearchFeedback = !loading && !timedOut && !search

  const { allChecked, naIdsToExcludeFromExport, naIdsToIncludeInExport } =
    useContext(ExportContext) || {}
  const newTotal = total > 10000 ? 10000 : total

  const errorHandler = (error) => {
    if (error) {
      console.log(error)
    }
  }

  return emptySearchFeedback ? (
    <EmptySearchFeedback />
  ) : error ? (
    <ErrorPage error={error} />
  ) : (!loading || !!query?.title || !!query?.naId) && total == 0 ? (
    <NoResultsFeedback term={query.q} />
  ) : (
    <main className={['width-full', 'bg-base-lightest'].join(' ')}>
      <div
        className={[
          'bg-primary-dark',
          'padding-x-1 tablet:padding-x-3',
          'padding-y-05 tablet:padding-y-0',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-gap-xs tablet-lg:flex-gap-sm',
            'flex-justify-center',
            'flex-no-wrap',
            'flex-row',
            'font-sans-2xs',
            'margin-x-auto',
            `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
            'minh-button',
            'text-base-lightest',
            'width-full',
          ].join(' ')}
        >
          <div className={['flex-1', 'margin-right-auto'].join(' ')}></div>
          <div
            className={[
              'display-flex',
              'flex-1',
              'flex-align-center',
              'flex-gap-xs',
              'flex-justify-end',
              'flex-row',
              'margin-left-auto',
            ].join(' ')}
          >
            <ExportButton
              data={exportData}
              search={queryString.parse(search)}
              data-testid="nac-search--export"
              theme="primary-lighter"
              total={total}
              limit={limit}
            />

            <ButtonSharePage
              data-testid="nac-search--share-page"
              theme="primary-lighter"
            />
            <ButtonDisplayOptions
              data-testid="nac-search--share-page"
              theme="primary-lighter"
            />
          </div>
        </div>
      </div>
      <section
        className={[
          'display-flex',
          'flex-gap',
          'flex-column',
          'grid-col',
          'margin-x-auto',
          'maxw-desktop-lg',
          'padding-x-3',
          'width-full',
        ].join(' ')}
      >
        {loading ? (
          <ResultsLoader />
        ) : (
          <>
            <div
              className={[
                'border-base-lighter',
                'border-bottom-1px',
                'display-flex',
                'flex-align-center',
                'flex-row',
                'grid-row',
                'margin-bottom-0',
                'padding-y-2',
                //'tablet:flex-row',
                //'tablet:grid-row',
                'width-full',
              ].join(' ')}
            >
              <Summary
                limit={parseInt(limit)}
                page={parseInt(page)}
                term={query.q}
                time={time}
                total={parseInt(total)}
              />
              <div className={['display-flex', 'margin-left-auto'].join(' ')}>
                <ResultsPerPage
                  active={limit.toString()}
                  handleUpdateValue={handleUpdateLimit}
                />
              </div>
            </div>
            <div
              className={[
                'display-flex',
                'flex-gap-lg',
                'flex-column',
                'grid-col',
                'margin-bottom-4',
                'margin-top-0',
                'tablet:flex-row',
                'tablet:grid-row',
                'width-full',
              ].join(' ')}
            >
              {filters && screenSize === 'desktop' && (
                <CatalogFilters
                  className={['display-none', 'tablet:display-block'].join(' ')}
                  filters={filters}
                  handleUpdateValue={handleUpdateFilters}
                />
              )}
              <div
                className={[
                  'display-flex',
                  'flex-gap-sm',
                  'flex-column',
                  'grid-col',
                ].join(' ')}
              >
                <div
                  className={['display-flex', 'flex-row', 'grid-row'].join(' ')}
                >
                  <div
                    className={[
                      'display-flex',
                      screenSize !== 'desktop' ? 'flex-wrap' : '',
                      'margin-right-auto',
                    ].join(' ')}
                  >
                    {screenSize !== 'desktop' ? (
                      <FilterButton
                        filters={filters}
                        handleUpdateFilters={handleUpdateFilters}
                        loading={loading}
                      />
                    ) : (
                      ''
                    )}
                    <div className="display-flex">
                      <CatalogSort
                        active={sort}
                        handleUpdateValue={handleUpdateSort}
                      />
                    </div>
                  </div>
                  <div className={['margin-left-auto'].join(' ')}>
                    <Pagination
                      current={parseInt(page)}
                      handleNextObject={nextPage}
                      handlePrevObject={previousPage}
                      iconOnly={screenSize !== 'desktop'}
                      id="results-top"
                      label="Page"
                      navLabel="Search results page navigation (top)"
                      //outline
                      reduced
                      textOnly
                      theme="base-darker"
                      total={pages}
                      updateCurrentObject={updatePage}
                    />
                  </div>
                </div>
                <div
                  className={[
                    'display-flex',
                    'flex-row',
                    'flex-gap',
                    'flex-align-center',
                    'margin-left-neg-1',
                  ].join(' ')}
                >
                  <SelectOptionsButton
                    data-testid="nac-search--select-options"
                    results={results}
                    total={total}
                  />
                  {((allChecked && naIdsToExcludeFromExport.length !== total) ||
                    naIdsToIncludeInExport.length > 0) && (
                    <span className="font-sans-2xs text-base-dark">
                      {allChecked
                        ? newTotal - naIdsToExcludeFromExport.length >= 10000
                          ? '10,000 results'
                          : `${
                              newTotal - naIdsToExcludeFromExport.length > 1
                                ? `${returnNumberWithCommas(
                                    newTotal - naIdsToExcludeFromExport.length
                                  )} results`
                                : '1 result'
                            }`
                        : `${
                            naIdsToIncludeInExport.length > 1
                              ? `${naIdsToIncludeInExport.length} results`
                              : '1 result'
                          }`}{' '}
                      selected
                    </span>
                  )}
                </div>
                {timedOut && (
                  <Alert type="warning">
                    The search took too long and timed out. Only some results
                    are listed here. Please refine your search, and try again.
                  </Alert>
                )}
                {results && (
                  <Results
                    data-page-total={pages}
                    data-result-total={total}
                    data-testid="nac-results"
                    limit={parseInt(limit)}
                    main
                    page={parseInt(page)}
                    results={results}
                    total={total}
                  ></Results>
                )}
                <div
                  className={['display-flex', 'flex-row', 'grid-row'].join(' ')}
                >
                  {pages > 1 && (
                    <div className={['margin-left-auto'].join(' ')}>
                      <Pagination
                        current={parseInt(page)}
                        handleNextObject={nextPage}
                        handlePrevObject={previousPage}
                        label="Page"
                        id="results-bottom"
                        navLabel="Search results page navigation (bottom)"
                        //outline
                        reduced
                        textOnly
                        theme="base-darker"
                        total={pages}
                        updateCurrentObject={updatePage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </main>
  )
}

SearchResults.defaultProps = {}

SearchResults.propTypes = {}

export default SearchResults
