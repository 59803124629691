import { useEffect, useReducer, useState } from 'react'
import { useLocation } from 'react-router-dom'
//Reducers
import { formReducer, removeEmpty } from '../reducers/form_reducer'
//Helpers
import {
  clearNullEmpties,
  isNullOrEmpty,
} from '../components/form/use-repeatable-fields'

const useForm = (includedArray, onSubmit) => {
  //Hooks
  const { state } = useLocation()

  //Reducers
  const [formArray, formDispatch] = useReducer(formReducer, {
    errors: [],
    fields: {},
    params: {},
    visible: {},
  })

  //State
  // True when the form has been changed from it's default state
  const [touched, setTouched] = useState(false)

  let allowedValuesExist = false
  includedArray?.map((a) => {
    if (keyExists(formArray.fields, a)) allowedValuesExist = true
  })
  let remove = Object.assign({}, formArray.fields)
  delete remove['dataSource']
  delete remove['availableOnline']
  //Vars
  const formIsEmpty =
    (formArray.fields.length === 1 && formArray.fields['dataSource']) ||
    isNullOrEmpty(clearNullEmpties(remove)) ||
    (includedArray && !allowedValuesExist)

  //Functions
  const formSubmitHandler = (event) => {
    event.preventDefault()
    if (formArray.errors?.length > 0) return false

    let params = []
    let newParams = Object.assign(
      {},
      ...(function _flatten(o) {
        return [].concat(
          ...Object.keys(o).map((k) => {
            let v = o[k]
            if (Array.isArray(v)) v = v.filter((v) => v != '').join(',')
            if (typeof v === 'object') {
              return removeEmpty(v)
            } else {
              return {
                [k]: v
                  .toString()
                  ?.replace(/(^,)|(,$)/g, '')
                  .trim(),
              }
            }
          })
        )
      })(formArray.fields)
    )
    for (const [key, value] of Object.entries(newParams)) {
      if (key === 'q' && value && typeof value !== 'undefined') {
        sessionStorage.setItem('search', value)
      }
      if (value && typeof value !== 'undefined') {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    }
    onSubmit(params)
  }

  const formResetHandler = (event) => {
    event?.preventDefault()
    document.getElementById('advanced-search').reset()
    setTouched(false)
    formDispatch({
      type: 'UPDATE_FORM_FIELD',
      //
      value: {},
    })
  }

  return {
    formErrors:
      Object.keys(formArray.errors).length > 0 ? formArray.errors : null,
    formFields: formArray.fields,
    formParams: formArray.params,
    formVisible:
      Object.keys(formArray.visible).length > 0 ? formArray.visible : null,
    formDispatch,
    formIsEmpty,
    formResetHandler,
    formSubmitHandler,
    touched,
    setTouched,
  }
}
export default useForm

export const keyExists = (obj, key) => {
  if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
    return false
  } else if (obj[key]) {
    return true
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const result = keyExists(obj[i], key)
      if (result) {
        return result
      }
    }
  } else {
    for (const k in obj) {
      const result = keyExists(obj[k], key)
      if (result) {
        return result
      }
    }
  }

  return false
}
