import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import '../../styles/index.scss'

//Settings
import {
  // DEFAULT_CONTENTS_PANEL,
  DEFAULT_PAGE,
  DEFAULT_PANEL,
  SEARCH_URL,
  DISABLE_JSON,
} from '../settings/globals'
//Context
import ContributionContext from '../../contexts/ContributionContext'
import ObjectSearchContext from '../../contexts/ObjectSearchContext'
import RecordContext from '../../contexts/RecordContext'
import ScrollContext from '../../contexts/ScrollContext'
// import SearchContext from '../../contexts/SearchContext'
//Components

import SectionTags from './section_tags'
import SectionComments from './section_comments'
import DigitalObjects from '../DigitalObjects/DigitalObjects'
import ErrorPage from '../Pages/ErrorPage/ErrorPage'
import SectionSubtitle from './section_subtitle'
import Section from './section'
import SectionFileUnits from './section_electronic_records'
import SectionReferenceUnits from './section_reference_units'
import SectionDonors from './section_donors'
import SectionControlNumbers from './section_control_numbers'
import SectionJson from './section_json'
import SectionResources from './section_resources'
import SectionTerms from './section_terms'
import Subjects from './section_subjects'
import { Button } from '../Button/Button'
import { ChildRecords } from '../Templates/RelatedRecords/ChildRecords'
import { DateLabel } from '../IconLabel/variants/DateLabel'
import { IconLabel } from '../IconLabel/IconLabel'
import { ListSeparator } from '../ListSeparator/ListSeparator'
import { OrganizationPage } from '../Pages/AuthorityPages/OrganizationPage'
import { PageHeader } from '../Templates/PageHeader/PageHeader'
import { RelatedRecords } from '../Templates/RelatedRecords/RelatedRecords'

//Helpers
import {
  returnIconAndLabelFromString,
  fileTypes,
  imageTypes,
  videoTypes,
  audioTypes,
} from '../../helper-functions/metadata-functions'
import { returnSortedDateString } from '../../helper-functions/date-functions'
import {
  convertCamelToSentence,
  returnNumberWithOrdinalSuffix,
} from '../../helper-functions/string-functions'
import { reduceArray } from '../../helper-functions/eval-functions'
//Hooks
import useHttp from '../../hooks/use-http'
//Reducers
import { tagsReducer } from '../../reducers/tags_reducer'
// import { recordReducer } from '../../reducers/record_reducer'
import { commentsReducer } from '../../reducers/comments_reducer'
import { transcriptionsReducer } from '../../reducers/transcriptions_reducer'

import SectionPartOf from './section_part_of'
import { DescriptionLoader } from './description_loader'
import SectionContributors from './section_contributors'
import SectionDescription from './section_description'
import SectionArchivedCopies from './section_archived_copies/section_archived_copies'
import SectionCreators from './section_creators'
import PrintPage from '../utilities/PrintPage'
import SectionAccessRestriction from './section_access-restriction'
import SectionUseRestriction from './section_use-restriction'
import useDetectPrinting from '../../hooks/use-detect-printing'
import { returnNumberWithCommas } from '../../helper-functions/number-functions'
import useDescriptionParams from './use-description-params'
import useScreenSize from '../../hooks/use-screen-size'
import UserContext from '../../contexts/UserContext'
import { returnRecordTitle } from '../Templates/Results/result-title'
import { Alert } from '../Alert/Alert'
import DescriptionNav from './description_nav'
import useUrlParams from '../../hooks/use-url-params'
import { AlertOnlineAvailability } from '../OnlineAvailability/AlertOnlineAvailability'
import useOnlineAvailability from './use-online-availability'
import { returnFileFormat } from '../DigitalObjects/object_preview'
import SearchContext from '../../contexts/SearchContext'
import Highlighter from '../Highlighter/Highlighter'
// import useReactGA from '../../hooks/use-ga'

const Description = ({ ...props }) => {
  // Get current params and param functions
  const { params } = useUrlParams()
  // const { sendEvent } = useReactGA()

  // Create state to house meaningful values based on params
  // const contributionId = params['contributionId'] || null
  const objectPanel = params['objectPanel'] || DEFAULT_PANEL
  const objectPage = params['objectPage'] || DEFAULT_PAGE

  const { handleScrollTo } = useContext(ScrollContext) || {}
  const { loggedInUser } = useContext(UserContext) || {}
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const { naId } = useParams()
  const { hash: urlHash, pathname, state } = useLocation()
  let { controlGroup = '*' } = state || {}

  //Fix loading contribution use state
  const [loadingContributions, setLoadingContributions] = useState(true)
  const [record, setRecord] = useState(null)
  //Digital Object Search State and Functions
  const [error, setError] = useState(null)
  const [input, setInput] = useState('')
  const [query, setQuery] = useState('')
  const [results, setResults] = useState({})
  const [contributionError, setContributionError] = useState(null)
  const [activeContributionId, setActiveContributionId] = useState(
    params['contributionId'] || null
  )
  const [activeObjectPage, setActiveObjectPage] = useState(objectPage)
  const [activeObjectType, setActiveObjectType] = useState('Object')
  const [contributionsHandler, setContributionsHandler] = useState(false)
  const [activePanel, setActivePanel] = useState(objectPanel)
  const handleSetObjectSearchResults = (results) => {
    setResults(results)
  }
  const { availability, counts } = useOnlineAvailability(record)
  const globalObjectSearchState = {
    input: input,
    setInput: setInput,
    query: query,
    setQuery: setQuery,
    results: results,
    setResults: handleSetObjectSearchResults,
  }

  //This record data
  const [hash, setHash] = useState(null)
  const [searchWithin, setSearchWithin] = useState(false)
  //Heading metadata
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [roles, setRoles] = useState([])
  const [theme, setTheme] = useState('primary-lighter')
  //Digital Object states
  const [audio, setAudio] = useState([])
  const [files, setFiles] = useState([])
  const [images, setImages] = useState([])
  // const [loadingImages, setLoadingImages] = useState(true)
  const [videos, setVideos] = useState([])
  //Contribution data
  const [parentControlGroup, setParentControlGroup] = useState('*')

  const { screenSize } = useScreenSize()

  const [loadingRecord, setLoadingRecord] = useState(false)

  const paramsArray = useDescriptionParams(
    loadingContributions || false,
    record || null
  )

  const requestUrl = useMemo(() => {
    let searchQuery = ''
    switch (record?.authorityType) {
      case 'person':
        return `record.donors.naId%3A${record.naId}%20OR%20record.creators.naId%3A${record.naId}%20OR%20record.contributors.naId%3A${record.naId}%20OR%20record.subjects.naId%3A${record.naId}%20OR%20record.personalReferences.naId%3A${record.naId}`
      case 'organizationName':
        return `record.donors.naId%3A${record.naId}%20OR%20record.creators.naId%3A${record.naId}%20OR%20record.contributors.naId%3A${record.naId}%20OR%20record.subjects.naId%3A${record.naId}%20OR%20record.organizationalReferences.naId%3A${record.naId}`
      case 'organization':
        return `record.donors.naId%3A${record.organizationNames[0].naId}%20OR%20record.creators.naId%3A${record.organizationNames[0].naId}%20OR%20record.contributors.naId%3A${record.organizationNames[0].naId}%20OR%20record.subjects.naId%3A${record.organizationNames[0].naId}%20OR%20record.organizationalReferences.naId%3A${record.organizationNames[0].naId}`
      case 'topicalSubject':
        return `record.subjects.naId%3A${record.naId}`
      case 'specificRecordsType':
        return `record.subjects.naId%3A${record.naId}`
      case 'geographicPlaceName':
        return `record.subjects.naId%3A${record.naId}%20OR%20record.jurisdictions.naId%3A${record.naId}`
    }

    // if (record?.levelOfDescription) {
    //   const recordType =
    //     /'person'|'organization'|'topicalSubject'|'specificRecordsType'|'geographicPlaceName'|'organizationName'/i.test(
    //       record?.levelOfDescription
    //     )
    //       ? 'authority'
    //       : 'description'

    //   const recordObj = {
    //     url: `${window.location.href}`,
    //     recordType: recordType,
    //     hasDigitObjects: record.digitalObjects?.length > 0 ? true : false,
    //   }

    // sendEvent({
    //   action: 'GET_RECORDS',
    //   category: 'RECORD_TYPE_STATS',
    //   label: JSON.stringify(recordObj),
    // })
    // }
  })

  useEffect(() => {
    return () => setSearchWithin(false)
  }, [])

  const recordState = {
    ...paramsArray,
    // activePanel: objectPanel,
    // activeObjectPage: objectPage,
    naId,
    controlGroup: '*', // controlGroup || '*',
    loadingRecord,
    setLoadingRecord,
    record,
    setRecord,
    setError,
    screenSize,
    activeContributionId,
    setActiveContributionId,
    activeObjectPage,
    setActiveObjectPage,
    activeObjectType,
    setActiveObjectType,
    activePanel,
    setActivePanel,
    availability,
    counts,
  }

  const [enteredTranscription, setEnteredTranscription] = useState(null)
  const [unsavedEdits, setUnsavedEdits] = useState(false)

  const [tags, tagsDispatch] = useReducer(tagsReducer, [])
  const [{ comments, counts: commentCounts }, commentsDispatch] = useReducer(
    commentsReducer,
    []
  )
  const [
    {
      activeTranscriptions,
      allTranscriptions,
      currentActiveTranscription,
      currentTranscription,
      currentTranscriptionIsLocked,
      lockedTranscriptions,
      parentContributionId,
      transcriptionEditRequestIsPending,
      transcriptionError,
      transcriptionIsCanceling,
      transcriptionIsPublishing,
      transcriptionIsSaved,
      transcriptionIsSaving,
      userHasTimedOut,
    },
    transcriptionsDispatch,
  ] = useReducer(transcriptionsReducer, [])

  const enteredTranscriptionHandler = (value) => {
    setEnteredTranscription(value)
  }

  const handleContributionResponse = (results) => {
    // Remove error if one was set previously and was not removed
    setContributionError(null)

    let flattenedTags = []
    let flattenedComments = []
    let flattenedTranscriptions = []
    let flattenedAdminTags = []

    results.map((contribution) => {
      const fromObjectId = contribution.targetObjectId
      if (contribution.madeByAdministrator) {
        contribution.userName = 'NARA Citizen Archivist'
        contribution.fullName = 'NARA Citizen Archivist'
        contribution.userId = '555555-5555-5555-5555-555555555555'
      }

      //Generate individual contribution type arrays to store in reducer
      switch (contribution.contributionType) {
        case 'tag':
          if (contribution.status === 'active') flattenedTags.push(contribution)
          break
        case 'comment':
          flattenedComments.push(contribution)
          break
        case 'transcription':
          flattenedTranscriptions.push(contribution)
          break
      }

      //Inject contribution data into the record array for
      //easy access in other components while maintainig record context
      if (fromObjectId && record.digitalObjects) {
        //record.contributions = record.contributions ? ... : ...
        //This method will return falsey if the variable "record.contributions" is any of the following:
        //false, undefined, null, zero, NaN, '' (empty string)
        record.digitalObjects.map((obj) => {
          if (parseInt(fromObjectId) === parseInt(obj.objectId)) {
            obj.contributions = obj.contributions
              ? [...obj.contributions, contribution]
              : [contribution]
          }
        })
      } else {
        record.contributions = record.contributions
          ? [...record.contributions, contribution]
          : [contribution]
      }
    })

    tagsDispatch({ type: 'FETCH_TAGS', tags: flattenedTags })
    commentsDispatch({
      type: 'FETCH_COMMENTS',
      comments: reduceArray(flattenedComments),
    })
    transcriptionsDispatch({
      type: 'FETCH_TRANSCRIPTIONS',
      allTranscriptions: flattenedTranscriptions,
      objectId: objectId,
    })

    setLoadingContributions(false)
  }

  const contributionErrorHandler = (error) => {
    setContributionError(error ? error : null)
  }

  //Contribution fetch request
  const {
    error: apiContributionsError,
    isLoading: contributionsLoading,
    sendRequest: requestContributionsHandler,
  } = useHttp(
    {
      url: `${SEARCH_URL}/contributions/targetNaId/${record?.naId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleContributionResponse,
    contributionErrorHandler
  )

  /**
   * Variables and functions available in the ContributionContext.
   * Comprised of record-level details.
   * Access to object-level details with requires the active objectId
   */
  const contributionState = {
    loadingContributions,
    //Comments
    comments,
    commentCounts: commentCounts,
    commentsDispatch,
    //Tags
    tags,
    tagsDispatch,
    //Transcriptions
    activeTranscriptions,
    allTranscriptions,
    currentActiveTranscription,
    currentTranscription,
    currentTranscriptionIsLocked,
    loggedInUserIsEditing:
      currentTranscription?.userId === loggedInUser?.userId &&
      currentTranscriptionIsLocked,
    enteredTranscription,
    enteredTranscriptionHandler,
    lockedTranscriptions,
    parentContributionId,
    transcriptionEditRequestIsPending,
    transcriptionError,
    transcriptionIsCanceling,
    transcriptionIsPublishing,
    transcriptionIsSaved,
    transcriptionIsSaving,
    unsavedEdits,
    setUnsavedEdits,
    userHasTimedOut,
    transcriptionsDispatch,
  }

  const objectId = paramsArray.objectId

  const renderSectionJson = () => {
    if (!DISABLE_JSON) {
      return (
        <SectionJson
          data-testid="nac-description__content--json"
          id="temp-json"
        />
      )
    } else {
      return
    }
  }

  // Only fetch contributions once the record has been fetched successfully...
  // ...and the current active objectId is available, as this isused by...
  // ...the contribution reducers

  useEffect(() => {
    if (!record) return false

    const abortController = new AbortController()

    if (!contributionError) requestContributionsHandler(abortController)

    setTheme(
      record.digitalObjects || record.levelOfDescription == 'item'
        ? 'white'
        : record.authorityType
        ? 'primary-lighter'
        : record.levelOfDescription
        ? 'ink'
        : 'primary-lighter'
    )

    handleSetDigitalObjects(record)
    handleSetBreadcrumbs(record)
    handleSetRoles(record)

    return () => {
      setContributionError(null)
    }
  }, [record, objectId])

  /*
  // Use to break out the requestContributionsHandler from the above useEffect,
  // in case of future issues.
  
  useEffect(() => {
    if (!objectId) return false
    const abortController = new AbortController()
    if (!contributionError) requestContributionsHandler(abortController)
    return () => {
      setContributionError(null)
      abortController.abort()
    }
  }, [objectId])
  */

  const handleSetSearchWithin = (bool) => {
    setSearchWithin(bool)
  }

  useEffect(() => {
    if (loadingRecord) return false
    window.scrollTo(0, 0)
    if (urlHash) setHash(urlHash)
  }, [loadingRecord, urlHash])

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [naId])

  useEffect(() => {
    if (!hash) return false
    const handler = setTimeout(() => {
      handleScrollTo(hash.replace('#', ''))
    }, 500)
    return () => {
      clearTimeout(handler)
    }
  }, [hash, loadingContributions])

  useEffect(() => {
    if (!record || (record && record.length == 0)) return false
    record.ancestors?.map((rec) => {
      if (
        rec.levelOfDescription == 'recordGroup' ||
        rec.levelOfDescription == 'collection'
      )
        setParentControlGroup(String(rec.naId))
    })
  }, [record])

  const handleSetRoles = (record) => {
    let rolesString = []
    if (record.organizationNames) {
      record.organizationNames.map((org) => {
        if (org.heading == record.heading) {
          for (const [key, value] of Object.entries(org.role)) {
            if (value) {
              rolesString.push(
                <span key={key} className="font-sans-xs">
                  {convertCamelToSentence(key)}
                </span>
              )
            }
          }
        }
      })
    } else if (record.role) {
      for (const [key, value] of Object.entries(record.role)) {
        if (value) {
          rolesString.push(
            <span key={key} className="font-sans-xs">
              {convertCamelToSentence(key)}
            </span>
          )
        }
      }
    }
    setRoles(rolesString)
  }

  const handleSetDigitalObjects = (record) => {
    if (!record || !record.digitalObjects) {
      setImages([])
      setVideos([])
      setAudio([])
      setFiles([])
      return
    }
    const objectsArray = record?.digitalObjects
    if (!objectsArray) return false
    // Use filter to create an array of just images
    let imageOnlyObjects = objectsArray?.filter(
      ({ objectType, objectUrl }) =>
        objectUrl && imageTypes.some((element) => objectType?.includes(element))
    )
    // Use filter to create an array of just videos
    let videoOnlyObjects = objectsArray?.filter(
      ({ objectType, objectUrl }) =>
        objectUrl && videoTypes.some((element) => objectType?.includes(element))
    )
    //Use filter to create an array of just audio files
    let audioOnlyObjects = objectsArray.filter(
      ({ objectType, objectUrl }) =>
        objectUrl && audioTypes.some((element) => objectType?.includes(element))
    )
    //Use filter to create an array of just files
    let fileOnlyObjects = objectsArray?.filter(
      ({ objectType, objectUrl }) =>
        objectUrl &&
        !imageTypes
          .filter((i) => !fileTypes.includes(i))
          .some((element) => objectType?.includes(element)) &&
        !videoTypes
          .filter((i) => !fileTypes.includes(i))
          .some((element) => objectType?.includes(element)) &&
        !audioTypes
          .filter((i) => !fileTypes.includes(i))
          .some((element) => objectType?.includes(element))
    )

    setImages(imageOnlyObjects)
    setVideos(videoOnlyObjects)
    setAudio(audioOnlyObjects)
    setFiles(fileOnlyObjects)
  }

  const handleSetBreadcrumbs = (record) => {
    if (!record || !record.ancestors) {
      setBreadcrumbs([])
    } else {
      let crumbs = []
      // let controlGroup = '*'
      record?.ancestors?.map((ancestor) => {
        if (
          ancestor.levelOfDescription == 'recordGroup' ||
          ancestor.levelOfDescription == 'collection'
        ) {
          // controlGroup = String(ancestor.naId)
        }
      })
      setParentControlGroup(controlGroup)
      record?.ancestors?.map((ancestor) => {
        if (ancestor.levelOfDescription == 'recordGroup') {
          crumbs.push({
            title: `Record Group ${ancestor.recordGroupNumber}: ${ancestor.title}`,
            // link: '/id/' + String(ancestor.naId),??
            link: `/id/${ancestor.naId.toString()}`,
            controlGroup: controlGroup,
          })
        }
        if (ancestor.levelOfDescription == 'collection') {
          crumbs.push({
            title: `Collection ${ancestor.collectionIdentifier}: ${ancestor.title}`,
            link: `/id/${ancestor.naId.toString()}`,
            controlGroup: controlGroup,
          })
        }
        if (ancestor.levelOfDescription == 'series') {
          crumbs.push({
            title: `Series: ${ancestor.title}`,
            link: `/id/${ancestor.naId.toString()}`,
            controlGroup: controlGroup,
          })
        }
        if (ancestor.levelOfDescription == 'fileUnit') {
          crumbs.push({
            title: `File Unit: ${ancestor.title}`,
            link: `/id/${ancestor.naId.toString()}`,
            controlGroup: controlGroup,
          })
        }
      })
      setBreadcrumbs(crumbs)
    }
  }

  const showDescription = useMemo(() => {
    if (!record) return false
    if (
      record.administrativeHistoryNote ||
      record.parent?.administrativeHistoryNote ||
      record.biographicalNote ||
      record.scopeAndContentNote ||
      record.scopeNote ||
      record.arrangement ||
      record.custodialHistoryNote ||
      record.functionAndUse ||
      record.generalNotes ||
      record.numberingNote ||
      record.scaleNote ||
      record.transferNote ||
      record.editStatus ||
      record.languages ||
      record.soundType ||
      record.beginCongress ||
      record.endCongress ||
      record.coverageStartDate ||
      record.coverageEndDate ||
      record.inclusiveStartDate ||
      record.inclusiveEndDate ||
      record.productionDates ||
      record.releaseDates ||
      record.broadcastDates ||
      record.copyrightDates ||
      record.productionSeriesTitle
    )
      return true
    return false
  }, [record])

  const showDates = useMemo(() => {
    if (!record) return false
    if (
      record.productionDates ||
      record.releaseDates ||
      record.broadcastDates ||
      record.coverageStartDate ||
      record.coverageEndDate ||
      record.inclusiveStartDate ||
      record.inclusiveEndDate ||
      record.birthDate ||
      record.deathDate
    )
      return true
    return false
  }, [record])

  const showTerms = useMemo(() => {
    if (!record) return false
    if (record.broaderTerms || record.narrowerTerms || record.relatedTerms)
      return true
    return false
  }, [record])
  const showMetaData = useMemo(() => {
    if (!record) return false
    if (
      (record.generalRecordsTypes && record.generalRecordsTypes.length > 0) ||
      showDates ||
      record.coordinates ||
      record.digitalObjects?.length > 0 ||
      roles.length > 0 ||
      record.beginCongress ||
      record.endCongress ||
      record.authorityType == 'topicalSubject'
    )
      return true
    return false
  }, [record])

  const showResources = useMemo(() => {
    if (!record) return false
    if (
      record.findingAids ||
      record.microformPublications ||
      record.onlineResources ||
      //record.recordSource ||
      record.sourceNotes
    )
      return true
    return false
  }, [record])

  const showCreators = useMemo(() => {
    if (!record) return false
    let ancestorCreators = false
    if (record.ancestors) {
      record.ancestors.map((ancestor) => {
        if (ancestor.creators) ancestorCreators = true
      })
    }
    if (record.creators || ancestorCreators) return true
    return false
  }, [record])

  const showAvailability = useMemo(() => {
    if (!record) return false
    return (
      availability &&
      record.recordType === 'description' &&
      (record.levelOfDescription !== 'item' ||
        (availability === 'notDigitized' &&
          record.levelOfDescription === 'item')) &&
      !record.digitalObjects
    )
  }, [availability])

  const showDigitalObjects = useMemo(() => {
    if (
      !images ||
      record?.variantControlNumbers?.filter(
        (n) => n.type === 'Download Display Identifier' && n.number === 'DDI'
      ).length > 0
    )
      return false
    else return true
  }, [images, record])

  {
    /*<PrintPage>*/
  }
  return (
    <RecordContext.Provider value={recordState}>
      <main className={['width-full'].join(' ')}>
        <ContributionContext.Provider value={contributionState}>
          <DescriptionNav />
        </ContributionContext.Provider>
        {error ? (
          <ErrorPage error={error} />
        ) : (
          <>
            <div className="minh-full flex-row grid-row flex-align-center flex-justify-center">
              {loadingRecord ? (
                <DescriptionLoader />
              ) : record ? (
                <div
                  className={['display-flex', 'flex-column', 'width-full'].join(
                    ' '
                  )}
                  data-testid="nac-description_full-result"
                >
                  <PageHeader
                    availability={availability}
                    theme={theme}
                    breadcrumbs={breadcrumbs}
                    record={record}
                    type={record.levelOfDescription || record.authorityType}
                    label={
                      record.levelOfDescription == 'recordGroup'
                        ? `Record Group ${record.recordGroupNumber}`
                        : record.levelOfDescription == 'collection'
                        ? `Collection ${record.collectionIdentifier}`
                        : record.levelOfDescription
                    }
                    title={`${returnRecordTitle(record)}${
                      (record.levelOfDescription === 'series' &&
                        record.inclusiveStartDate) ||
                      record.inclusiveEndDate
                        ? `, ${returnSortedDateString([
                            record.inclusiveStartDate,
                            record.inclusiveEndDate,
                          ])}`
                        : ''
                    }`}
                  >
                    {(record.otherTitles ||
                      record.useFor ||
                      record.subtitle ||
                      record.organizationNames ||
                      record.parent?.organizationNames ||
                      record.variantPersonNames) && (
                      <SectionSubtitle theme={theme} />
                    )}

                    {/* Header: Creator(s) */}
                    {record.creators && (
                      <div
                        className={[
                          'breadcrumbs',
                          'display-flex',
                          record.creators.length > 1
                            ? 'flex-column'
                            : 'flex-row',
                          record.creators.length > 1
                            ? 'grid-column'
                            : 'grid-row',
                          'flex-no-wrap',
                          'font-sans-md',
                          // 'line-height-sans-4',
                          'text-bold',
                          `theme-${theme}`,
                        ].join(' ')}
                        data-testid="nac-page-header--subtitle"
                      >
                        <span
                          className={[
                            'font-sans-sm',
                            'margin-right-1',
                            'text-italic',
                            `theme-${theme}--accent`,
                          ].join(' ')}
                        >
                          {record.creators.length > 1
                            ? `Creators:`
                            : `Creator:`}
                        </span>

                        {record.creators.map((creator, i) => (
                          <span key={`heading-creator-${creator.heading}`}>
                            <Highlighter search={q}>
                              {creator.heading}
                            </Highlighter>{' '}
                            ({creator.creatorType})
                          </span>
                        ))}
                      </div>
                    )}

                    {/* Header: Metadata with icons, such as data types and dates */}
                    {showMetaData && (
                      <div className="clearfix display-inline-flex flex-row grid-row">
                        <ListSeparator>
                          <IconLabel theme={theme} iconName="institution">
                            NAID: {record.naId}
                          </IconLabel>
                          {record.localIdentifier && (
                            <IconLabel theme={theme} iconName="institution">
                              Local ID: {record.localIdentifier}
                            </IconLabel>
                          )}
                          {record.generalRecordsTypes &&
                            record.generalRecordsTypes.length > 0 &&
                            record.generalRecordsTypes.map((type) => (
                              <IconLabel key={type} theme={theme}>
                                {type}
                              </IconLabel>
                            ))}
                          {showDates && (
                            <DateLabel record={record} theme={theme} />
                          )}
                          {record.coordinates && (
                            <IconLabel iconName="globe" theme={theme}>
                              Coordinates: {record.coordinates}
                            </IconLabel>
                          )}
                          {record.authorityType === 'geographicPlaceName' &&
                            record.linkCounts?.organization && (
                              <Link
                                to={{
                                  pathname: `/search`,
                                  search: `?q=${`record.jurisdictions.naId%3A${record.naId}`}`,
                                }}
                              >
                                <IconLabel iconName="group" theme={theme}>
                                  Jurisdiction of:{' '}
                                  {record.linkCounts.organization}
                                  {record.linkCounts.organization > 1
                                    ? ' organizations'
                                    : ' organization'}
                                </IconLabel>
                              </Link>
                            )}
                          {record.authorityType === 'person' &&
                            record.linkCounts?.organization && (
                              <Link
                                to={{
                                  pathname: `/search`,
                                  search: `?q=${`record.personalReferences.naId%3A${record.naId}`}`,
                                }}
                              >
                                <IconLabel iconName="group" theme={theme}>
                                  Related to:&nbsp;
                                  {record.linkCounts.organization}
                                  {record.linkCounts.organization > 1
                                    ? ' organizations'
                                    : ' organization'}
                                </IconLabel>
                              </Link>
                            )}
                          {images &&
                            images.filter(
                              (i) =>
                                returnFileFormat(i.objectFilename) !== 'pdf'
                            ).length > 0 && (
                              <IconLabel iconName="image" theme={theme}>
                                {
                                  images.filter(
                                    (i) =>
                                      returnFileFormat(i.objectFilename) !==
                                      'pdf'
                                  ).length
                                }{' '}
                                {images.filter(
                                  (i) =>
                                    returnFileFormat(i.objectFilename) !== 'pdf'
                                ).length > 1
                                  ? 'Images'
                                  : 'Image'}
                              </IconLabel>
                            )}
                          {videos && videos.length > 0 && (
                            <IconLabel iconName="video" theme={theme}>
                              {videos.length}{' '}
                              {videos.length > 1 ? 'Videos' : 'Video'}
                            </IconLabel>
                          )}
                          {audio && audio.length > 0 && (
                            <IconLabel iconName="audio" theme={theme}>
                              {audio.length}{' '}
                              {audio.length > 1 ? 'Audio Files' : 'Audio File'}
                            </IconLabel>
                          )}
                          {files && files.length > 0 && (
                            <IconLabel
                              iconName="document-download"
                              theme={theme}
                            >
                              {files.length}{' '}
                              {files.length > 1 ? 'Files' : 'File'}
                            </IconLabel>
                          )}
                          {roles && roles.length > 0 && (
                            <IconLabel
                              theme={theme}
                              iconName="name-tag"
                              iconSize="sm"
                            >
                              <ListSeparator
                                size="sm"
                                delimeter="plus"
                                distance="2px"
                                align="middle"
                              >
                                {roles}
                              </ListSeparator>
                            </IconLabel>
                          )}
                          {(record.beginCongress || record.endCongress) && (
                            <IconLabel
                              theme={theme}
                              iconName="institution"
                              iconSize="sm"
                            >
                              Congress:{' '}
                              {record.beginCongress === record.endCongress
                                ? returnNumberWithOrdinalSuffix(
                                    record.beginCongress
                                  )
                                : `${returnNumberWithOrdinalSuffix(
                                    record.beginCongress
                                  )}–${returnNumberWithOrdinalSuffix(
                                    record.endCongress
                                  )}`}
                            </IconLabel>
                          )}
                        </ListSeparator>
                      </div>
                    )}

                    {/* Header: Search within 
                        images.length == 0 &&
                        record.levelOfDescription !== 'item' &&
                      */}
                    {!record.authorityType &&
                      searchWithin &&
                      (record.fileUnitCount ||
                        record.seriesCount ||
                        record.itemCount) && (
                        <div
                          className={[
                            'clearfix',
                            'display-flex',
                            'flex-gap',
                            'flex-row',
                            'flex-wrap',
                          ].join(' ')}
                        >
                          <Button
                            data-testid="nac-description--search-within-button"
                            disabled={!searchWithin}
                            iconName="search"
                            iconSize="xs"
                            size="2xs"
                            thin
                            to={{
                              pathname: `/search-within/${naId}`,
                            }}
                          >
                            Search within this{' '}
                            {
                              returnIconAndLabelFromString(
                                record.levelOfDescription
                              ).label
                            }
                          </Button>
                          <div
                            className={[
                              'margin-bottom-1',
                              'display-inline-flex',
                              'flex-wrap',
                              'flex-align-center',
                            ].join(' ')}
                          >
                            <ListSeparator
                              size="md"
                              delimeter="pipe"
                              distance="1"
                              align="text-top"
                            >
                              {record.seriesCount && (
                                <IconLabel
                                  iconName="box"
                                  iconSize="xs"
                                  size="2xs"
                                  theme={theme}
                                >
                                  {returnNumberWithCommas(record.seriesCount)}{' '}
                                  Series
                                </IconLabel>
                              )}
                              {record.fileUnitCount && (
                                <IconLabel
                                  iconName="folder"
                                  iconSize="xs"
                                  size="2xs"
                                  theme={theme}
                                >
                                  {returnNumberWithCommas(record.fileUnitCount)}{' '}
                                  File Unit
                                  {record.fileUnitCount > 1 ? 's' : ''}
                                </IconLabel>
                              )}
                              {record.itemCount && (
                                <IconLabel
                                  iconName="items"
                                  iconSize="xs"
                                  size="2xs"
                                  theme={theme}
                                >
                                  {returnNumberWithCommas(record.itemCount)}{' '}
                                  Item
                                  {record.itemCount > 1 ? 's' : ''}
                                </IconLabel>
                              )}
                            </ListSeparator>
                          </div>
                        </div>
                      )}
                    {record.authorityType && (
                      <div
                        className={[
                          'clearfix',
                          'display-flex',
                          'flex-gap',
                          'flex-row',
                          'flex-wrap',
                        ].join(' ')}
                      >
                        <Button
                          data-testid="nac-description--search-related-button"
                          iconName="search"
                          iconSize="xs"
                          size="2xs"
                          thin
                          to={{
                            pathname: `/search`,
                            search: `?q=${encodeURIComponent(requestUrl)}`,
                          }}
                        >
                          Search Related
                        </Button>
                      </div>
                    )}
                  </PageHeader>
                  {showDigitalObjects && (
                    <ContributionContext.Provider value={contributionState}>
                      <ObjectSearchContext.Provider
                        value={globalObjectSearchState}
                      >
                        <DigitalObjects />
                      </ObjectSearchContext.Provider>
                    </ContributionContext.Provider>
                  )}
                  {files.length > 0 && <SectionFileUnits files={files} />}
                  <div
                    className={[
                      'display-flex',
                      'flex-column',
                      'flex-no-wrap',
                      'grid-col',
                      'padding-x-0',
                    ].join(' ')}
                  >
                    {/** True conditional rendering is important for performance */}
                    {showAvailability && (
                      <Section className="padding-bottom-0">
                        <AlertOnlineAvailability
                          counts={counts}
                          level={record.levelOfDescription}
                          type={availability}
                        />
                      </Section>
                    )}
                    {record.parent && (
                      <Section className="padding-bottom-0">
                        <Alert
                          data-testid="nac-description__alert--deprecated-org-name"
                          type="warning"
                        >
                          We have streamlined our organization name display in
                          the new Catalog. Please refer to the{' '}
                          <Link
                            to={{
                              pathname: `/id/${record.parent.naId}`,
                              search: `organizationNaId=${naId}`,
                            }}
                          >
                            updated full organization record
                          </Link>{' '}
                          instead.
                        </Alert>
                      </Section>
                    )}
                    {(showDescription ||
                      showTerms ||
                      record.subjects ||
                      record.accessRestriction ||
                      record.useRestriction) && (
                      <Section
                        className={[
                          showAvailability ? 'padding-top-0' : '',
                        ].join(' ')}
                      >
                        <div
                          className={[
                            'display-flex',
                            'flex-column',
                            'flex-gap-md',
                            'width-full',
                          ].join(' ')}
                        >
                          {showDescription && <SectionDescription />}
                          {(record.accessRestriction ||
                            record.useRestriction) && (
                            <>
                              {record.accessRestriction && (
                                <SectionAccessRestriction
                                  data-testid="nac-description__access"
                                  id="access-restriction"
                                />
                              )}
                              {record.useRestriction && (
                                <SectionUseRestriction
                                  data-testid="nac-description__use"
                                  id="use-restriction"
                                />
                              )}
                            </>
                          )}
                          {record.subjects && (
                            <Subjects
                              data-testid="nac-description__subjects"
                              id="subjects"
                            />
                          )}
                          {showTerms && <SectionTerms />}
                        </div>
                        {record.ancestors && (
                          <SectionPartOf
                            data-testid="nac-description__from"
                            id="part-of"
                          />
                        )}
                      </Section>
                    )}
                    {(record.organizationNames ||
                      record.parent?.organizationNames) && (
                      <Section>
                        <OrganizationPage
                          record={record}
                          data-testid="nac-section__organization-names"
                          id="organization-names"
                        />
                      </Section>
                    )}
                    {(record.seriesCount ||
                      record.fileUnitCount ||
                      record.itemCount) && (
                      <ChildRecords
                        record={record}
                        controlGroup={parentControlGroup}
                        setSearchWithin={handleSetSearchWithin}
                      />
                    )}
                    {record.authorityType && <RelatedRecords />}
                    <Section data-testid="nac-description__creators-contributors">
                      <div
                        className={[
                          'display-flex',
                          record.contributors && record.contributors.length > 0
                            ? [
                                'tablet-lg:flex-row',
                                'tablet-lg:flex-gap-lg',
                                'flex-column',
                                'flex-gap-0',
                              ].join(' ')
                            : ['flex-row', 'flex-gap-lg'].join(' '),
                        ].join(' ')}
                      >
                        {showCreators && (
                          <SectionCreators
                            data-testid="nac-description__creators"
                            id="creators"
                          />
                        )}
                        {record.contributors && (
                          <SectionContributors
                            data-testid="nac-description__contributors"
                            id="contributors"
                          />
                        )}
                      </div>
                    </Section>
                    {record.physicalOccurrences && (
                      <Section className="bg-base-lighter-opacity-50">
                        <SectionArchivedCopies
                          data-testid="nac-description__archived-copies"
                          id="archived-copies"
                        />
                      </Section>
                    )}
                    <Section data-testid="nac-description__resources">
                      {record.donors && (
                        <SectionDonors
                          data-testid="nac-description__donors"
                          id="donors"
                        />
                      )}
                      <SectionControlNumbers
                        data-testid="nac-description__control-numbers"
                        id="control-numbers"
                      />
                      {record.referenceUnits && (
                        <SectionReferenceUnits
                          data-testid="nac-description__reference-units"
                          id="reference-units"
                        />
                      )}
                      {showResources && (
                        <SectionResources
                          data-testid="nac-description__content--resources"
                          id="resources"
                        />
                      )}
                    </Section>
                    <Section
                      data-testid="nac-description__contributions"
                      className="bg-primary-lighter-opacity-50"
                    >
                      <ContributionContext.Provider value={contributionState}>
                        {record.authorityType === 'organizationName' && (
                          <Alert type="warning">
                            All new contributions to this page have been
                            disabled. Please add all tags or comments to the{' '}
                            <Link
                              to={{
                                pathname: `/id/${record.parent.naId}`,
                                hash: 'tags',
                              }}
                            >
                              updated Organization page here
                            </Link>
                            .
                          </Alert>
                        )}
                        <SectionTags
                          data-testid="nac-description__tags"
                          disabled={record.authorityType === 'organizationName'}
                          id="tags"
                        />
                        <SectionComments
                          data-testid="nac-description__comments"
                          disabled={record.authorityType === 'organizationName'}
                          id="comments"
                        />
                      </ContributionContext.Provider>
                    </Section>
                    <Section data-testid="nac-description__json">
                      {renderSectionJson()}
                    </Section>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </main>
    </RecordContext.Provider>
  )
}

Description.defaultProps = {}

Description.propTypes = {}

export default Description
