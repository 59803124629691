import React, { useState, useEffect } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import './styles/index.scss'
//Context
import HighlighterContext from './contexts/HighlighterContext'
import ScrollContext from './contexts/ScrollContext'
import SearchContext from './contexts/SearchContext'
import UserContext from './contexts/UserContext'
//Components
import Header from './components/Templates/Header'
import CatalogHome from './components/CatalogHome'
import Footer from './components/Templates/Footer'
import Description from './components/description/Description'
import HelpCenter from './components/Pages/HelpCenter/HelpCenter'
import HelpCenterPage from './components/Pages/HelpCenter/help-center-page'
import HelpCenterSection from './components/Pages/HelpCenter/help-center-section'
import SearchResults from './components/SearchResults'
import SearchWithin from './components/SearchWithin/SearchWithin'
import Account from './components/Account/Account'
//Hooks
import useLogin from './hooks/use-login'
import useSearch from './hooks/use-search'
import useScroll from './hooks/use-scroll'
import Test from './Test'
// import BannerOfficial from './components/Banner/variants/BannerOfficial'
import Notifications from './components/Account/restricted/Notifications'
import Insights from './components/Account/restricted/Insights'
import AccountOverview from './components/Account/public/AccountOverview'
import AccountTags from './components/Account/public/AccountTags'
import AccountComments from './components/Account/public/AccountComments'
import AccountTranscriptions from './components/Account/public/AccountTranscriptions'
import Lists from './components/Account/restricted/Lists'
import Downloads from './components/Account/restricted/Downloads'
import Settings from './components/Account/restricted/Settings'
import BannerHarmfulContent from './components/Banner/variants/BannerHarmfulContent'
import ModerateTags from './components/Account/restricted/ModerateTags'
import ModerateComments from './components/Account/restricted/ModerateComments'
import ModerateTranscriptions from './components/Account/restricted/ModerateTranscriptions'
import ModeratorContextWrapper from './components/Account/restricted/ModeratorContextWrapper'
import AdvancedSearchPage from './components/AdvancedSearchPage'
import OnlineAvailability from './components/Account/restricted/OnlineAvailability'
import Announcments from './components/Account/restricted/Announcements'
import { Accounts } from './components/Account/restricted/admin-dashboard/AccountsAdmin'
import { Icon } from '../src/components/Icon/Icon'

// Google Analytics (GA) Trackers
// The online documentation is a liar.
// Use the format specified in the definition of `initialization` for options & other values.
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import useHighlighter from './components/Highlighter/use-highlighter'
import BackgroundImages from './components/Account/restricted/BackgroundImages'
import ExportContext from './contexts/ExportContext'
import useExport from './hooks/use-export'
import useReactGA from './hooks/use-ga'
import HomepageBoxes from './components/Account/restricted/HomepageBoxes'
import FullDescription from './components/FullDescription'

ReactGA.initialize(
  [
    {
      trackingId: 'UA-17592594-36',
      gaOptions: {
        name: 'tracker1',
      },
    },
  ]
  // debug mode prints debug content to the console
  // { debug: true }
)
ReactGA4.initialize('G-0VS4QBSPD8')

const App = () => {
  const scrollState = useScroll()
  const searchState = useSearch()
  const loggedInState = useLogin()
  const location = useLocation()
  const highlighterState = useHighlighter()
  const exportState = useExport()
  const [showPopup, setShowPopup] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowFontSize, setWindowFontSize] = useState(
    window.innerWidth > 650 ? '18px' : '16px'
  )
  const [iconMarginTop, setIconMarginTop] = useState(
    window.innerWidth > 431 ? '-46px' : '-45px'
  )
  const [iconTop, setIconTop] = useState(
    window.innerWidth > 431 ? '-17px' : '-12px'
  )
  const [colorchangePaddingTop, setColorchangePaddingTop] = useState('10px')
  // const { sendPageView, sendHitType } = useReactGA()
  const [commentTop, setcommentTop] = useState(
    window.innerWidth > 431 ? '5px' : '2px'
  )
  const [commentWidth, setcommentWidth] = useState('22px')
  const [commentHeight, setcommentHeight] = useState('22px')
  const [commentRight, setcommentRight] = useState('80px')
  // Fire GA trackers once pageload, and on every location change
  useEffect(() => {
    const blacklistSearchList = ['objectPage']
    if (!blacklistSearchList.some((item) => location.search.includes(item))) {
      ReactGA.pageview(window.location.pathname + window.location.search, [
        'tracker1',
      ])
      ReactGA4.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      })
    }
  }, [location])
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth
      setWindowWidth(newWidth)
      setWindowFontSize(newWidth > 650 ? '18px' : '15px')
      if (newWidth <= 429) {
        setIconMarginTop('-46px')
        setIconTop('-13px')
        setWindowFontSize('12px')
        setcommentWidth('15px')
        setcommentHeight('15px')
        setcommentTop('11px')
        setcommentRight('54px')
        setColorchangePaddingTop('13px')
      } else if (newWidth <= 430) {
        setIconTop('-26px')
        setWindowFontSize('12px')
        setIconMarginTop('-51px')
        setcommentWidth('17px')
        setcommentHeight('17px')
        setcommentTop('12px')
        setcommentRight('69px')
      } else if (newWidth <= 462) {
        setIconTop('-19px')
        setcommentWidth('17px')
        setcommentHeight('17px')
        setcommentTop('10px')
        setcommentRight('69px')
      } else if (newWidth <= 494) {
        setIconMarginTop('-48px')
        setIconTop('-13px')
        setcommentWidth('17px')
        setcommentHeight('17px')
        setWindowFontSize('13px')
        setcommentTop('6px')
        setcommentRight('65px')
        setColorchangePaddingTop('10px')
      } else if (newWidth <= 650) {
        setIconMarginTop('-43px')
        setWindowFontSize('14px')
        setIconTop('-21px')
        setcommentTop('10px')
        setcommentRight('65px')
        setColorchangePaddingTop('12px')
      } else {
        setIconTop('-29px')
        setIconMarginTop('-46px')
        setColorchangePaddingTop('7px')
        setcommentTop('7px')
        setcommentWidth('22px')
        setcommentHeight('22px')
        setcommentRight('85px')
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const closePopup = () => {
    setShowPopup(false)
    sessionStorage.setItem('popupShown', 'true')
  }

  useEffect(() => {
    const popupShownBefore = sessionStorage.getItem('popupShown')
    const timeoutInMinutes = 3
    if (!popupShownBefore) {
      // const timeoutInMinutes = 3
      const timer = setTimeout(() => {
        setShowPopup(true)
        sessionStorage.setItem('popupShown', 'true')
      }, timeoutInMinutes * 60 * 1000)
      return () => clearTimeout(timer)
    }
  }, [])
  const popupStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    color: '#23496D',
    padding: '8px',
    borderRadius: '5px',
    width: '80%',
    maxWidth: '526px',
    height: '145px',
    position: 'fixed',
    zIndex: 1,
    fontSize: windowFontSize,
    textAlign: 'center',
    fontWeight: '500',
    left: '50%',
    top: '23%',
    transform: 'translate(-50%, -50%)',
    fontFamily:
      'Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
  }

  const colorchange = {
    width: windowWidth > 650 ? '241px' : '60%',
    height: '41px',
    position: 'relative',
    top: '38px',
    paddingTop: colorchangePaddingTop,
    fontWeight: '500',
    fontSize: windowFontSize,
    backgroundColor: 'rgb(35, 73, 109)',
    color: 'white',
    textAlign: 'center',
    borderRadius: '6px',
    textDecoration: 'none',
  }
  const headingStyle = {
    color: 'rgb(35, 73, 109)',
    fontWeight: '700',
    fontFamily:
      'Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
  }
  const commentstyle = {
    width: commentWidth,
    height: commentHeight,
    color: 'white',
    zIndex: '0',
    position: 'relative',
    top: commentTop,
    left: commentRight,
  }
  return (
    <UserContext.Provider value={loggedInState}>
      <SearchContext.Provider value={searchState}>
        <ScrollContext.Provider value={scrollState}>
          <HighlighterContext.Provider value={highlighterState}>
            <ExportContext.Provider value={exportState}>
              <div className="App overflow-hidden">
                {/*<ScrollToTop />
                  <BannerOfficial />*/}
                {/* <BannerHarmfulContent /> */}
                {showPopup && (
                  <div style={popupStyle}>
                    <div style={headingStyle}>
                      <p>We Want Your Feedback</p>
                    </div>
                    <p>
                      Tell us about your experience with the Catalog, including
                      suggestions for improvements.
                    </p>
                    <Icon
                      iconName="close"
                      iconSize="xs"
                      onClick={closePopup}
                      color="base-dark"
                      style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        marginTop: iconMarginTop,
                        marginRight: '-11px',
                        position: 'relative',
                        left: '-10px',
                        top: iconTop,
                      }}
                    />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.surveymonkey.com/r/NARAnewcatfb"
                      style={colorchange}
                      onClick={closePopup}
                    >
                      Feedback Survey
                    </a>
                    <Icon
                      style={commentstyle}
                      iconName="newtab"
                      iconSize="md"
                      color="white"
                    />
                  </div>
                )}
                <Header />
                <Routes>
                  {/**
                   * TO DO: Handle redirects server-side
                   */}
                  <Route
                    //Example: http://localhost:3000/id/7741397/15402084
                    path="id/:id/:objId"
                    element={<LegacyDescriptionUrl />}
                  />
                  {/** HOME */}
                  <Route path="/" element={<CatalogHome />} />

                  {/** SEARCH */}
                  <Route
                    path="advanced-search"
                    element={<AdvancedSearchPage />}
                  />
                  <Route path="search" element={<SearchResults />} />
                  <Route
                    path="search-within/:naId"
                    element={<SearchWithin />}
                  />

                  {/** RECORDS */}
                  <Route
                    path="search/id/full-details"
                    element={<FullDescription />}
                  />
                  <Route path="id/:naId" element={<Description />} />

                  {/** USER PAGES */}
                  <Route path="accounts/:userName" element={<Account />}>
                    <Route index element={<AccountOverview />} />
                    <Route
                      path=":feedPage"
                      element={
                        <ProtectedRoute
                          accessLevels={loggedInState.accountsAccessArray}
                          redirectPath=".."
                          schema={accountsAccessSchema}
                        />
                      }
                    >
                      <Route
                        path=":moderationPage/"
                        element={
                          <ProtectedRoute
                            accessLevels={loggedInState.accountsAccessArray}
                            redirectPath="../.."
                            schema={moderatorAccessSchema}
                          />
                        }
                      />
                    </Route>
                  </Route>

                  {/** HELP */}
                  <Route path="help">
                    <Route index element={<HelpCenter />} />
                    <Route path=":pageId">
                      <Route index element={<HelpCenterPage />} />
                      <Route
                        path=":sectionId"
                        element={<HelpCenterSection />}
                      />
                    </Route>
                  </Route>
                </Routes>
                <Footer />
              </div>
            </ExportContext.Provider>
          </HighlighterContext.Provider>
        </ScrollContext.Provider>
      </SearchContext.Provider>
    </UserContext.Provider>
  )
}
export default App

export const LegacyDescriptionUrl = ({ ...props }) => {
  const { id, objId } = useParams()

  return (
    <Navigate
      to={{
        pathname: `/id/${id}`,
        search: `${objId < 2000 ? `objectPage=` : `objectId=`}${objId}`,
      }}
    />
  )
}

/**
 * Prevents unauthorized users from accesing restricted areas of the site.
 */
export const ProtectedRoute = ({
  accessLevels,
  children,
  isAllowed,
  redirectPath, // = '/'
  schema,
}) => {
  const { feedPage, moderationPage } = useParams()
  if (!isAllowed)
    schema.map((s) => {
      if (s.route === moderationPage || s.route === feedPage) {
        isAllowed = accessLevels[s.isAllowed]
        children = s.component
      }
    })
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}

ProtectedRoute.propTypes = {
  /**
   * Specifies the access levels as an object of booleans to be referenced in the schema array
   */
  accessLevels: PropTypes.object,
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Determines if the current user has permission to access the route
   */
  isAllowed: PropTypes.bool,
  /**
   * The path to redirect to in case the user does not meet permission conditions
   */
  redirectPath: PropTypes.string,
  /**
   * Specifies the schema array to reference
   */
  schema: PropTypes.array,
}

/**
 * Defines access to specific pages
 */
export const accountsAccessSchema = [
  {
    component: <AccountTags />,
    isAllowed: 'accessGrantedToPublicDetails',
    route: 'tags',
  },
  {
    component: <AccountComments />,
    isAllowed: 'accessGrantedToPublicDetails',
    route: 'comments',
  },
  {
    component: <AccountTranscriptions />,
    isAllowed: 'accessGrantedToPublicDetails',
    route: 'transcriptions',
  },
  {
    component: <Insights />,
    isAllowed: 'accessGrantedToAccountDetails',
    route: 'insights',
  },
  {
    component: <Notifications />,
    isAllowed: 'accessGrantedToAccountDetails',
    route: 'notifications',
  },
  {
    component: <Lists />,
    isAllowed: 'isLoggedInUsersPage',
    route: 'lists',
  },
  {
    component: <Downloads />,
    isAllowed: 'isLoggedInUsersPage',
    route: 'downloads',
  },
  {
    component: <Settings />,
    isAllowed: 'isLoggedInUsersPage',
    route: 'settings',
  },
  {
    component: <Test />,
    isAllowed: 'accessGrantedToAccountAdminTools',
    route: 'moderate-accounts',
  },
  {
    component: <ModeratorContextWrapper />,
    isAllowed: 'accessGrantedToModeratorTools',
    route: 'moderator-dashboard',
  },
  {
    component: <Accounts />,
    isAllowed: 'accessGrantedToModeratorTools',
    route: 'accounts',
  },
]

export const moderatorAccessSchema = [
  {
    component: <ModerateTags />,
    isAllowed: 'accessGrantedToModeratorTools',
    route: 'moderate-tags',
  },
  {
    component: <ModerateComments />,
    isAllowed: 'accessGrantedToModeratorTools',
    route: 'moderate-comments',
  },
  {
    component: <ModerateTranscriptions />,
    isAllowed: 'accessGrantedToModeratorTools',
    route: 'moderate-transcriptions',
  },
  {
    component: <OnlineAvailability />,
    isAllowed: 'accessGrantedToModeratorTools',
    route: 'online-availability',
  },
  {
    component: <BackgroundImages />,
    isAllowed: 'accessGrantedToAccountAdminTools',
    route: 'background-images',
  },
  {
    component: <Announcments />,
    isAllowed: 'accessGrantedToModeratorTools',
    route: 'moderate-announcements',
  },
  {
    component: <HomepageBoxes />,
    isAllowed: 'accessGrantedToModeratorTools',
    route: 'moderate-homepage-boxes',
  },
]
