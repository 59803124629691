import React, { useContext, useEffect } from 'react'
import DOMPurify from 'dompurify'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import useHighlighter from './use-highlighter'
import HighlighterContext from '../../contexts/HighlighterContext'
// TO DO: Account for scenarios where a term is indexed within multiple other terms (such as 'oo' in both 'book' and 'took')

/**
 * The Highlighter component is used to denote content which has a degree of relevance.
 * More specifically, the Hilighter component wraps relevant content in a mark tag,
 * which allows it to stand out.
 *
 * DO: Use this component to indicates a portion of the document's content
 * which is likely to be relevant to the user's current activity.
 * This will most likely be used to indicate the words that matched the search operation.
 *
 * DO NOT: Use this component for syntax highlighting purposes;
 * instead, use the <span> element with appropriate CSS applied to it.
 */

const Highlighter = ({ children, exact, search, ...props }) => {
  const { displayHighlights } = useContext(HighlighterContext)
  if (!displayHighlights) return children
  if (!children || children == '' || Array.isArray(children)) return children
  if ((!search || search == '') && exact) return <mark>{children}</mark>
  if ((!search || search == '') && !exact) return children

  search = search.replace(/\s+/g, ' ').trim()
  // How to escape regular expression special characters using javascript?
  // [https://stackoverflow.com/questions/3115150/how-to-escape-regular-expression-special-characters-using-javascript/9310752#9310752]
  const escapeRegExpSearchString = (text) => {
    return text.toString()?.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&')
  }
  // Get quoted text to treat as a single highlight in array
  // Doing this first preserves booleans and modifiers which reside within quotes
  const quotedArray =
    search
      ?.match(/"(.*?)"/g)
      ?.map((v) => v.replace(/"/g, ''))
      ?.filter(function (v) {
        return v !== ''
      }) || []

  // Get individual remaining  strings, but remove booleans and other modifiers
  let searchArray = []
  if (quotedArray.length > 0) {
    searchArray =
      search
        ?.replace(/"(.*?)"/g, '')
        ?.replace(/\s*(\b(and|or|\*)\b)\s*/gi, ' ')
        ?.replace(/\s*(\b(not)\b)\s*\w*\s*/gi, ' ')
        ?.split(' ')
        ?.filter(function (v) {
          return v !== ''
        }) || []
  } else
    searchArray =
      search
        ?.replace(/\s*(\b(and|or|\*)\b)\s*/gi, ' ')
        ?.replace(/\s*(\b(not)\b)\s*\w*\s*/gi, ' ')
        ?.split(' ') || []

  // const newArray = [...quotedArray, ...searchArray].join(' ')
  // TO DO: Remove all NOT statements and their immediate preceeding content from string?

  //Strip all brackets and parentheses as they cause problems
  // search = search
  search = searchArray
    .join(' ')
    .trim()
    .replace(/[\])}[{(]/g, '')
  const removeHtmlFromString = (text) => {
    return text.toString()?.replace(/<[^>]*>/g, '')
  }

  const createTextSearchMarkup = (
    fragment,
    search,
    isCaseSensitive = false
  ) => {
    const flags = isCaseSensitive ? 'g' : 'gi'
    const newSearch = search.split(' ')
    newSearch.sort((x, y) => y.length - x.length)

    newSearch.forEach((term) => {
      term = escapeRegExpSearchString(term)

      let split = []
      newSearch.forEach((t) => {
        if (term !== t && t.indexOf(term) > -1) {
          split.push(t.split(term))
        }
      })

      let newterm, beginning, end
      split.forEach((not) => {
        if (not[0] !== '') beginning = `?!${escapeRegExpSearchString(not[0])}`
        if (not[1] !== '') end = `?!${escapeRegExpSearchString(not[1])}`
      })

      if (
        typeof beginning !== 'undefined' &&
        typeof end !== 'undefined' &&
        term !== ''
      ) {
        newterm = RegExp(
          `(${beginning})(?![^<]*>)(${term})(?![^<]*<\\/[^>]*>)|(?![^<]*>)(${term})(${end})`,
          flags
        )
        fragment = fragment.replace(
          newterm,
          '$1<mark><strong>$3</strong></mark>$4'
        )
      } else if (
        typeof beginning !== 'undefined' &&
        typeof end === 'undefined' &&
        term !== ''
      ) {
        newterm = RegExp(`(${beginning})(?![^<]*>)(${term})`, flags)
        fragment = fragment.replace(
          newterm,
          '$1<mark><strong>$3</strong></mark>'
        )
      } else if (
        typeof beginning === 'undefined' &&
        typeof end !== 'undefined' &&
        term !== ''
      ) {
        newterm = RegExp(`(${term})(?![^<]*<\\/[^>]*>)(${end})`, flags)
        fragment = fragment.replace(newterm, '<mark><strong>$1</strong></mark>')
      } else if (
        typeof beginning === 'undefined' &&
        typeof end === 'undefined' &&
        term !== ''
      ) {
        newterm = RegExp(`(?![^<]*>)(${term})(?![^<]*<\\/[^>]*>)`, flags)
        fragment = fragment.replace(newterm, '<mark><strong>$1</strong></mark>')
      }
    })

    fragment = fragment.replace(/<\/mark><mark>/g, '')
    fragment = fragment.replace(/<\/mark> <mark>/g, ' ')
    return fragment
  }

  const concatTextSearchMarkup = (collector, fragment) => {
    const regXTag = /^<[^>]+>$/
    if (!regXTag.test(fragment)) {
      fragment = createTextSearchMarkup(
        fragment,
        collector.search,
        collector.isCaseSensitive
      )
    }
    collector.string = [collector.string, fragment].join('') // concat.}

    return collector
  }

  const getHighlightTextSearchMarkup = (string, search, isCaseSensitive) => {
    // if (!string) return false

    const regXSimpleMarkup = /(<[^>]+>)([^<]+)(<\/[^>]+>)/g //TO DO: What does this do, exactly?
    // Handle quoted strings
    if (quotedArray.length > 0) {
      quotedArray.map((q) => {
        string = string.replace(
          RegExp(`(${q})`, 'gi'),
          '<mark><strong>$1</strong></mark>'
        )
      })
    }
    // Handle remaining strings
    if (search)
      return string?.split(regXSimpleMarkup).reduce(concatTextSearchMarkup, {
        isCaseSensitive,
        search,
        string: '',
      }).string
    else return string
  }

  const highlightText = getHighlightTextSearchMarkup(
    removeHtmlFromString(children),
    search,
    false
  )

  const clean = DOMPurify.sanitize(highlightText, {
    USE_PROFILES: { html: true },
  })

  function createMarkup() {
    return {
      __html: clean,
    }
  }

  // Return Statement
  if (search || quotedArray.length > 0) {
    return <span dangerouslySetInnerHTML={createMarkup()}></span>
  } else {
    return removeHtmlFromString(children)
  }
}

Highlighter.defaultProps = {
  children: '',
  exact: false,
  search: '',
}

Highlighter.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
  /**
   * Forces the entire string to be returned highlighted, as opposed to individual words
   */
  exact: PropTypes.bool,
  search: PropTypes.string,
}

export default Highlighter
