import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
//Components
import { Button } from '../../Button/Button'

// const queryString = require('query-string')

export const errorList = ({ error, ...props }) => {
  const navigate = useNavigate()
  // const { search } = useLocation()
  // const q = queryString.parse(search)

  return [
    {
      id: 'DEFAULT',
      title: 'Looks like we took a wrong turn...',
      desc: 'The system is experiencing a problem.',
      action: (
        <>
          <p>
            If the problem persists,{' '}
            <a
              href={`mailto:catalog@nara.gov?subject=Experienced ${
                error
                  ? `a '${error.status}: ${error.statusText} (${error.id})'`
                  : 'an'
              } error on the Catalog Website.`}
              tabIndex="0"
            >
              click here to contact catalog@nara.gov
            </a>{' '}
            for assistance.
          </p>
          <p>
            <Button
              data-testid="nac-error--prev-page-button"
              iconName="prev"
              onClick={() => navigate(-1)}
              reduced
              role="button"
            >
              <span className="usa-sr-only">Click this button to </span> Return
              to the previous page
            </Button>
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/12462518.jpg',
        title: 'Car Still Sinking',
        naId: '12462518',
        desc: 'A black-and-white photograph of a vintage car resting tire-high in a body of water. 2-3 figures casually sit in the car, and one person appears to be smiling joyfully.',
        alt: 'A black-and-white photograph of a vintage car resting tire-high in a body of water. 2-3 figures casually sit in the car, and one person appears to be smiling joyfully.',
      },
    },
    {
      id: 'SERVER_NOT_AVAIL',
      title: 'Don’t worry, we’re on it!',
      action: (
        <>
          <p>
            If the problem persists,{' '}
            <a
              href={`mailto:catalog@nara.gov?subject=Experienced ${
                error
                  ? `a '${error.status}: ${error.statusText} (${error.id})'`
                  : 'an'
              } error on the Catalog Website.`}
              tabIndex="0"
            >
              click here to contact catalog@nara.govs
            </a>{' '}
            for assistance.
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/519420.jpg',
        title:
          'Cutting telegraph wire and connecting the ends, so that the point at which the connection is broken cannot be seen from the ground.',
        naId: '519420',
        desc: 'Someone standing near the top of an electrical pole adjusting the wires.',
        alt: 'Someone standing near the top of an electrical pole adjusting the wires.',
      },
    },
    {
      id: 'ES_NOT_AVAIL',
      title: 'This may take a moment...',
      action: (
        <>
          <p>
            If the problem persists,{' '}
            <a
              href={`mailto:catalog@nara.gov?subject=Experienced ${
                error
                  ? `a '${error.status}: ${error.statusText} (${error.id})'`
                  : 'an'
              } error on the Catalog Website.`}
              tabIndex="0"
            >
              click here to contact catalog@nara.gov
            </a>{' '}
            for assistance.
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/86729702.jpg',
        title: 'Telephone switchboard operators',
        naId: '86729702',
        desc: 'Four operators, each with a head set, sit in front of a wall-sized switchboard and dilligentlly work to connect calls.',
        alt: 'Four operators, each with a head set, sit in front of a wall-sized switchboard and dilligentlly work to connect calls.',
      },
    },
    {
      id: 'ES_TIMEOUT',
      title: 'Looks like we are out of time.',
      action: (
        <>
          <p>
            If the problem persists,{' '}
            <a
              href={`mailto:catalog@nara.gov?subject=Experienced ${
                error
                  ? `a '${error.status}: ${error.statusText} (${error.id})'`
                  : 'an'
              } error on the Catalog Website.`}
              tabIndex="0"
            >
              click here to contact catalog@nara.gov
            </a>{' '}
            for assistance.
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/196001795.jpg',
        title: 'A. Frankfeld Double Stop-Movements for Watches',
        naId: '196001795',
        desc: 'A detailed patent drawing of two figures.',
        alt: 'A detailed patent drawing of two figures.',
      },
    },
    {
      id: 'PAGE_NOT_FOUND',
      title: 'It must be around here, somewhere!',
      action: (
        <>
          <p>
            Please check the URL to make sure it is correct, or{' '}
            <a href="/" tabIndex="0">
              click here to try a new search
            </a>
            !
          </p>
          <p>
            If the problem persists,{' '}
            <a
              href={`mailto:catalog@nara.gov?subject=Experienced ${
                error
                  ? `a '${error.status}: ${error.statusText} (${error.id})'`
                  : 'an'
              } error on the Catalog Website.`}
              tabIndex="0"
            >
              click here to contact catalog@nara.gov
            </a>{' '}
            for assistance.
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/175539855.jpg',
        title: 'Photograph Showing New Accession of Records',
        naId: '175539855',
        desc: 'Someone carefully reviewing the documents from a large chest in a warehouse filled with filing cabinets.',
        alt: 'Someone carefully reviewing the documents from a large chest in a warehouse filled with filing cabinets.',
      },
    },
    {
      id: 'NO_RESULTS',
      title: 'End of the road...',
      action: (
        <>
          <p>
            <a href="/" tabIndex="0">
              Click here to try a new search
            </a>
            , or return to the previous page.
          </p>
          <p>
            <Button
              data-testid="nac-error--prev-page-button"
              iconName="prev"
              onClick={() => navigate(-1)}
              reduced
              role="button"
            >
              <span className="usa-sr-only">Click this button to </span> Return
              to the previous page
            </Button>
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/12462518.jpg',
        title: 'Car Still Sinking',
        naId: '12462518',
        desc: 'A black-and-white photograph of a vintage car resting tire-high in a body of water. 2-3 figures casually sit in the car, and one person appears to be smiling joyfully.',
        alt: 'A black-and-white photograph of a vintage car resting tire-high in a body of water. 2-3 figures casually sit in the car, and one person appears to be smiling joyfully.',
      },
    },
    {
      id: 'NO_RESULTS_TIMED_OUT',
      title: 'Looks like we are out of time.',
      action: (
        <>
          <p>
            This can happen if the search is not specific enough, or the system
            was experiencing problems. Please{' '}
            <a onClick={() => navigate(-1)}>
              refine your search on the previous page
            </a>
            , or{' '}
            <a href="/" tabIndex="0">
              try a new search
            </a>
            .
          </p>
          <p>
            <Button
              data-testid="nac-error--prev-page-button"
              iconName="prev"
              onClick={() => navigate(-1)}
              reduced
              role="button"
            >
              <span className="usa-sr-only">Click this button to </span> Return
              to the previous page
            </Button>
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/196001795.jpg',
        title: 'A. Frankfeld Double Stop-Movements for Watches',
        naId: '196001795',
        desc: 'A detailed patent drawing of two figures.',
        alt: 'A detailed patent drawing of two figures.',
      },
    },
    {
      id: 'MAX_PAGE_LIMIT',
      title: "We've reached our limit!",
      action: (
        <>
          <p>
            <Button
              data-testid="nac-error--prev-page-button"
              iconName="prev"
              onClick={() => navigate(-1)}
              reduced
              role="button"
            >
              <span className="usa-sr-only">Click this button to </span> Return
              to the previous page
            </Button>
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/32207057.jpg',
        title: 'Valve Wheel Stuck - Overflow',
        naId: '32207057',
        desc: 'Water spilling over a stone dam.',
        alt: 'Water spilling over a stone dam.',
      },
    },
    {
      id: 'INVALID_REQUEST',
      title: 'Does not compute.',
      action: (
        <>
          <p>
            <Button
              data-testid="nac-error--prev-page-button"
              iconName="prev"
              onClick={() => navigate(-1)}
              reduced
              role="button"
            >
              <span className="usa-sr-only">Click this button to </span> Return
              to the previous page
            </Button>
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/6380551.jpeg',
        title:
          'A view of ROBART I, an autonomous sentry robot developed by Lieutenant Commander Bart Everett, at the Naval Surface Weapons Center',
        naId: '6380551',
        desc: 'A black and white photograph of a tall, rectilinear robot made of metal and other various materials.',
        alt: 'A black and white photograph of a tall, rectilinear robot made of metal and other various materials.',
      },
    },
    {
      id: 'MAX_RESULTS',
      title: 'End of the road...',
      action: (
        <>
          <p>
            <Button
              data-testid="nac-error--prev-page-button"
              iconName="prev"
              onClick={() => navigate(-1)}
              reduced
              role="button"
            >
              <span className="usa-sr-only">Click this button to </span> Return
              to the previous page
            </Button>
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/12462518.jpg',
        title: 'Car Still Sinking',
        naId: '12462518',
        desc: 'A black-and-white photograph of a vintage car resting tire-high in a body of water. 2-3 figures casually sit in the car, and one person appears to be smiling joyfully.',
        alt: 'A black-and-white photograph of a vintage car resting tire-high in a body of water. 2-3 figures casually sit in the car, and one person appears to be smiling joyfully.',
      },
    },
    {
      id: 'USER_NOT_FOUND',
      title: "We'll keep an eye out!",
      action: (
        <>
          <p>
            The user page you&apos;re trying to reach doesn&apos;t exist. This
            may be because the username was changed, or there may be a spelling
            mistake.
          </p>
          <p>Check the URL to make sure the username is spelled correctly.</p>
          <p>
            <Button
              data-testid="nac-error--prev-page-button"
              iconName="prev"
              onClick={() => navigate(-1)}
              reduced
              role="button"
            >
              <span className="usa-sr-only">Click this button to </span> Return
              to the previous page
            </Button>
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/205577689.jpg',
        title: 'Photograph of Sailors on Lookout',
        naId: '205577689',
        desc: 'Two Coast Guardsmen are on lookout, one with their back turned and the other peers through binoculars.',
        alt: 'Two Coast Guardsmen are on lookout, one with their back turned and the other peers through binoculars.',
      },
    },
    {
      id: 'EMPTY_QUERY',
      title: 'We need some more information...',
      action: (
        <>
          <p>
            Not sure where to begin? Try these suggestions:
            <ul className="margin-left-3 margin-top-2 display-block">
              <li className="margin-top-1">
                If you are searching for a person&apos;s name, read our{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.archives.gov/research/genealogy/catalog-guide"
                >
                  Search Tips for Family Historians
                </a>
              </li>
              <li className="margin-top-1">
                Read more about{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.archives.gov/research/catalog/help/search-tips"
                >
                  How to Search.
                </a>
              </li>
              <li className="margin-top-1">
                Visit our{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.archives.gov/research/topics"
                >
                  Research Topics Pages
                </a>{' '}
                for more ideas.
              </li>
              <li className="margin-top-1">
                If you would like to request copies of military service records,
                use our separate online system called{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.archives.gov/veterans/military-service-records/"
                >
                  eVetRecs
                </a>{' '}
                to make your request.
              </li>
              <li className="margin-top-1">
                Read about{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.archives.gov/research/whats-here.html"
                >
                  What&apos;s here and what&apos;s not
                </a>
                {' and '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.archives.gov/research/search/help/whats-included.html"
                >
                  What is included in this search?
                </a>
              </li>
            </ul>
          </p>
          <p>
            <Button
              data-testid="nac-error--prev-page-button"
              iconName="prev"
              onClick={() => navigate(-1)}
              reduced
              role="button"
            >
              <span className="usa-sr-only">Click this button to </span> Return
              to the previous page
            </Button>
          </p>
        </>
      ),
      imageContent: {
        image: '/images/errors/74228254.jpg',
        title:
          'Photograph of Researcher in the Central Search Room of the National Archives Building',
        naId: '74228254',
        desc: 'A black and white photograph of someone sitting upright in a leather studded desk chair. They use a large magnifying glass to peer at a page of an abnormally large open book, which rests on a large wooden stand on a desk. Several other large books are stacked to the side.',
        alt: 'A black and white photograph of someone sitting upright in a leather studded desk chair. They use a large magnifying glass to peer at a page of an abnormally large open book, which rests on a large wooden stand on a desk. Several other large books are stacked to the side.',
      },
    },
    /*{
    status: 504,
    statusText: 'Gateway Timeout',
    title: 'Things are moving a little slow...',
    desc: 'The process took too long.',
    action:
      'Please try again in a moment.\n\nIf the problem persists, contact catalog@nara.gov for assistance.',
    imageContent: {
      image: '/images/errors/557675.jpg',
      title:
        'JOHNNY ADAMS (IN CAP) AND SUMMER ROOMMATE, DAVID CROOK TRAIN THEIR PET TURTLE FOR THE FOURTH OF JULY RACES AT NEARBY UNICOI STATE PARK',
      naId: '557675',
      desc: 'The title says it all.',
      alt: 'JOHNNY ADAMS (IN CAP) AND SUMMER ROOMMATE, DAVID CROOK TRAIN THEIR PET TURTLE FOR THE FOURTH OF JULY RACES AT NEARBY UNICOI STATE PARK',
    },
  },*/
  ]
}

errorList.defaultProps = {
  error: null,
}

errorList.propTypes = {
  /** The error array object returned from the API or fetch method */
  error: PropTypes.object,
}
