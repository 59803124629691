import React, {
  forwardRef,
  Fragment,
  useContext,
  useMemo,
  useState,
} from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import ContributionContext from '../../contexts/ContributionContext'
import UserContext from '../../contexts/UserContext'
//Components
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'

Array.prototype.contains = function (element) {
  return this.indexOf(element) > -1
}

export const ContentsContributionButton = forwardRef(
  (
    { contribution, format, index, object, onClick, extractedData, ...props },
    ref
  ) => {
    const { activeTranscriptions, commentCounts, lockedTranscriptions, tags } =
      useContext(ContributionContext) || {}
    const { loggedInUser } = useContext(UserContext) || {}
    let count = 0
    let replies = 0
    switch (contribution) {
      case 'comment':
        count = commentCounts?.objectCommentCounts[object.objectId] || 0
        break
      case 'tag':
        count =
          tags?.length &&
          tags.filter((tag) => {
            return parseInt(tag.targetObjectId) === parseInt(object.objectId)
          }).length
        break
      case 'extracted':
      case 'extractedText':
        count = typeof object?.extractedText === 'string' ? 1 : 0
        break
      case 'transcription':
        if (activeTranscriptions) {
          count = activeTranscriptions.contains(parseInt(object.objectId))
            ? 1
            : 0
        }

        break
    }

    if (contribution === 'extractedText') {
      contribution = 'extracted'
    }

    let s = contribution == 'transcription' ? '' : count > 1 ? 's' : ''
    const transcriptionLocked =
      lockedTranscriptions &&
      lockedTranscriptions.contains(parseInt(object.objectId))

    const transcriptionStatus =
      contribution === 'transcription'
        ? count > 0
          ? loggedInUser?.userId && transcriptionLocked
            ? 'Available (Locked)'
            : 'Available'
          : loggedInUser?.userId && transcriptionLocked
          ? 'In Progress (Locked)'
          : 'Not Started'
        : ''

    let iconName =
      format === 'grid'
        ? contribution === 'transcription' &&
          loggedInUser?.userId &&
          transcriptionLocked
          ? 'transcription-locked'
          : contribution
        : null

    if (contribution === 'extracted') {
      iconName = 'extractedtext'
    }

    return (
      <Button
        data-testid={`nac-objects--details-object-${
          index + 1
        }--${contribution}-panel-button`}
        iconName={iconName}
        iconSize="xs"
        onClick={() => onClick(index + 1, contribution)}
        reduced
        ref={ref}
        size="3xs"
        textOnly
        theme="base-lightest"
        thin={count == 0}
      >
        <span className={`${count == 0 ? 'text-base-light' : 'text-bold'}`}>
          {contribution == 'transcription' ? (
            format == 'grid' ? (
              count > 0 ? (
                <Icon
                  color="base-lightest"
                  iconName="check"
                  iconSize="2xs"
                  strokeThickness="thick"
                />
              ) : (
                <Icon color="base-light" iconName="close" iconSize="xs" />
              )
            ) : (
              transcriptionStatus
            )
          ) : (
            count
          )}
        </span>
        <span className="usa-sr-only">
          {count > 0
            ? `${count} ${contribution}${s} available. Access the ${contribution}${s} for object ${
                index + 1
              }`
            : `No ${contribution}${
                contribution !== 'transcription' ? 's' : ''
              } available. Go to the ${contribution}${
                contribution !== 'transcription' ? 's' : ''
              } panel for object ${index + 1}`}
        </span>
      </Button>
    )
  }
)

ContentsContributionButton.defaultProps = {
  format: 'grid',
}

ContentsContributionButton.propTypes = {
  extractedData: PropTypes.number,
  contribution: PropTypes.string,
  format: PropTypes.string,
  index: PropTypes.number,
  object: PropTypes.object,
  onClick: PropTypes.func,
}

ContentsContributionButton.displayName = 'ContentsContributionButton'
