import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import '../../styles/index.scss'
//PropTypes
import PropTypes from 'prop-types'
//Context
import SearchContext from '../../contexts/SearchContext'
//Components
import Select from './select'
import TextInput from './text_input'
import InputMessage from './input_message'
import {
  validateInputFromSchema,
  ValidationError,
} from '../form/Validate/Validate'
//Hooks
import useInput from '../../hooks/use-input'
import useScreenSize from '../../hooks/use-screen-size'
import FormContext from '../../contexts/FormContext'
import { useMemo } from 'react'
import RangeField from './RangeField'

const SelectInput = forwardRef(
  (
    {
      groupId,
      id,
      onChange,
      options,
      schema,
      values,
      removeRuleBool,
      setRemoveRuleBool,
      ...props
    },
    ref
  ) => {
    if (!schema) return false

    //Context
    const { formDispatch, formFields, setTouched } = useContext(FormContext)

    //Hooks
    const { screenSize } = useScreenSize()

    //Refs
    const inputRef = useRef(null)
    const selectRef = useRef(null)

    //State
    // const defaultSelection = useMemo(() => {
    //   return (
    //     defaultSelected || schema.filter((s) => s.isDefault || s.isPlaceholder)
    //   )
    // }, [])

    const [selected, setSelected] = useState([options[0]])
    const [inputError, setInputError] = useState(null)

    //Functions
    const inputErrorHandler = (error) => {
      setInputError(
        typeof error === 'string' ? new ValidationError(error) : error
      )
    }

    const selectHandler = (event, idSelected) => {
      //Update global form touch state
      setTouched(true)
      //Update field group value in context
      formDispatch({
        type: 'REMOVE_FORM_FIELDS',
        id: selected[0].id,
      })
      onChange(idSelected, enteredValue, selected[0].id)
      //Set the selected schema
      setSelected(schema.filter((s) => idSelected === s.id))
    }

    // Handles resetting the form field within the form array
    // as well as the pinput field value using the local ref
    const reset = () => {
      formDispatch({
        type: 'REMOVE_FORM_FIELDS',
        id: selected[0].id,
      })
      resetHandler()
      if (inputRef?.current) inputRef.current.value = ''
      setSelected(schema.filter((s) => s.isDefault || s.isPlaceholder))
    }

    const {
      isTouched: inputIsTouched,
      isValid: enteredValueIsValid,
      value: enteredValue,
      inputBlurHandler: blurHandler,
      valueChangeHandler: changeHandler,
      // focusHandler,
      reset: resetHandler,
    } = useInput(
      (value) => validateInputFromSchema(selected[0], value),
      inputErrorHandler,
      formFields?.[groupId] && formFields?.[groupId][selected[0].id]
    )

    useEffect(() => {
      if (removeRuleBool) {
        inputRef.current.value =
          ref.current?.id === id ? ref.current.value : values[id]

        setSelected(schema.filter((s) => s.id === id))
        setRemoveRuleBool(false)
      }
    }, [removeRuleBool])

    useEffect(() => {
      formDispatch({
        type: 'SET_FORM_ERRORS',
        id: selected[0].id,
        error: inputError || null,
      })
    }, [inputError])

    useEffect(() => {
      onChange(selected[0].id, enteredValue)
    }, [enteredValue])

    //https://reactjs.org/docs/hooks-reference.html#useimperativehandle
    useImperativeHandle(ref, () => ({
      id: id,
      error: inputError,
      reset: reset,
      value: enteredValue,
    }))

    let fieldValue = ''
    if (formFields[groupId]) {
      fieldValue = formFields[groupId][id]
    }

    return selected?.[0].type ? (
      <div
        className={[
          'display-flex',
          screenSize === 'mobile'
            ? 'flex-column flex-gap-0'
            : 'flex-row flex-gap-sm',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            screenSize === 'mobile'
              ? 'width-full margin-bottom-neg-205'
              : 'grid-col-7',
          ].join(' ')}
        >
          <Select
            onBlur={blurHandler}
            onChange={selectHandler}
            options={options}
            ref={selectRef}
            selected={selected}
            schema={schema.filter((s) => s.id === id)}
          />
        </div>
        <div className="width-full">
          {selected[0].type === 'text' && (
            <TextInput
              error={inputError}
              groupId={groupId}
              onBlur={blurHandler}
              onChange={changeHandler}
              value={fieldValue}
              ref={inputRef}
              schema={selected[0]}
            />
          )}
          {selected[0].type === 'range' && (
            <RangeField
              error={inputError}
              onBlur={blurHandler}
              onChange={changeHandler}
              ref={inputRef}
              schema={selected[0]}
              {...props}
            />
          )}
          <InputMessage schema={selected[0]} message={inputError} />
        </div>
      </div>
    ) : (
      ''
    )
  }
)

SelectInput.defaultProps = {}

SelectInput.propTypes = {
  /**
   * The default selected schema object
   */
  defaultSelected: PropTypes.array,
  /**
   * The id to associate with the input group within the greater form
   */
  groupId: PropTypes.string,
  /**
   * The id to associate with the individual input schema
   */
  id: PropTypes.string,
  /**
   * Additional function to handle change event in parent component
   */
  onChange: PropTypes.func,
  /**
   * An array of predefined select options, if they differ from those provided by the schema
   * Mainly used for repeatable fields where it is important to prevent selecting the same select option multiple times.
   */
  options: PropTypes.array,
  /**
   * The specific schema associated with the selected field id
   * used to build and validate the input
   */
  schema: PropTypes.array,
  values: PropTypes.object,
  removeRuleBool: PropTypes.bool,
  setRemoveRuleBool: PropTypes.func,
}

SelectInput.displayName = 'SelectInput'
export default SelectInput
