import React, { forwardRef, useContext } from 'react'
import '../../styles/index.scss'
//PropTypes
import PropTypes from 'prop-types'
import { inputSchemaPropTypes } from '../../schemas/input-schemas/input-schema-prop-types'
//Context
import SearchContext from '../../contexts/SearchContext'
import FormContext from '../../contexts/FormContext'

const TextInput = forwardRef(
  ({ className, error, groupId, schema, ...props }, ref) => {
    const { formFields } = useContext(FormContext)
    const errorInputClasses =
      error?.type === 'error'
        ? 'invalid border-error-dark bg-error-lighter'
        : error?.type === 'warning'
        ? 'warning border-accent-warm-dark bg-accent-warm-lighter'
        : ''

    if (schema.type !== 'text' && schema.type !== 'number') return false

    return (
      <input
        aria-invalid={error && error.type === 'error'}
        aria-required={schema.required}
        autoComplete={schema.autoComplete ? 'on' : 'off'}
        className={[
          'usa-input',
          className,
          errorInputClasses,
          //
          'bg-white',
          'border-base-dark',
          'border-1px',
          'display-inline-block',
          'line-height-sans-4',
          'padding-105',
          schema.examples ? 'margin-bottom-3' : '',
          'margin-top-0',
          'maxw-full',
          'radius-md',
          schema.icon
            ? [
                'nac-input-icon',
                'nac-icon--base',
                'nac-icon--bg-50',
                'nac-icon--left',
                `nac-icon--${schema.icon}`,
                'nac-icon--thin',
                'padding-left-4',
              ].join(' ')
            : '',
          'width-full',
        ].join(' ')}
        data-testid={`nac-input_${schema.id}`}
        id={`nac-text-input_${schema.id}`}
        maxLength={schema.maxLength}
        minLength={schema.minLength}
        name={schema.id}
        placeholder={schema.placeholder}
        ref={ref}
        required={schema.required}
        type="text" //{schema.type}
        defaultValue={
          formFields?.[groupId] ? formFields[groupId][schema.id] : ''
        }
        {...props}
      />
    )
  }
)

TextInput.defaultProps = {}

TextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  /**
   * Id of a group of inputs for more granular identification
   */
  groupId: PropTypes.string,
  schema: inputSchemaPropTypes,
}

TextInput.displayName = 'TextInput'
export default TextInput
