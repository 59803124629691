import React, { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import Header from '../Templates/Header'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const PopupWindow = ({
  msg,
  window,
  setThumbs,
  children,
  setData,
  naids,
  ...props
}) => {
  const newWindow = useRef(null)
  const [container, setContainer] = useState(null)

  useEffect(() => {
    if (!newWindow.current) {
      newWindow.current = window.open(
        '',
        '',
        'width=600,height=400,left=200,top=200'
      )
      const newContainer = document.createElement('div')

      newContainer.style.padding = '1rem'

      newContainer.id = 'export-brief'
      newWindow.current.document.body.appendChild(newContainer)
      newWindow.current.document.title = 'Printable full description documents'
      setContainer(newContainer)
      const style = `
        html,body{
          background-color:white;
        }
      
        .no-print {
          display: none;
        }
      
        #nac-header{
          margin: 0 0 .5rem 0;
        }
      
        #nac-header div div:not(:first-child){
          display:none;
        }
      
        #nac-header div ol{
          display:none;
        }
      
        .nac-icon {
          display: ${/yes/gi.test(setThumbs) ? 'block !important' : 'none'};
        }
  
        #export-brief .link-msg{
          font-weight: 500;
          font-size: .75rem;
          margin: .5rem 0;
        }
      
          @media print{
            body, html,export-brief{
              height: 100% !important;
              width: 100% !important;
              overflow: visible !important;
            }
            #nac-header{
              display: block !important;
              margin: 0 0 .5rem 0;
            }
      
            #nac-header div div:not(:first){
              display: none;
            }
  
            .nac-icon {
              display: ${/yes/gi.test(setThumbs) ? 'block !important' : 'none'};
            }
          }`

      const mediaCSS = document.createElement('style')
      mediaCSS.textContent = style
      newWindow.current.document
        .getElementsByTagName('head')[0]
        .appendChild(mediaCSS)

      Array.from(document.styleSheets).forEach((sheet) => {
        if (sheet.cssRules) {
          const newStyle = document.createElement('style')
          Array.from(sheet.cssRules).forEach((cssRule) => {
            newStyle.appendChild(document.createTextNode(cssRule.cssText))
          })
          newWindow.current.document.head.appendChild(newStyle)
        } else if (sheet.href) {
          // true for stylesheets loaded from a URL
          const newLinkEl = document.createElement('link')

          newLinkEl.rel = 'stylesheet'
          newLinkEl.href = sheet.href
          newWindow.current.document.head.appendChild(newLinkEl)
        }
      })

      const scriptSheet = document.createElement('script')
      newWindow.current.document
        .getElementsByTagName('head')[0]
        .appendChild(scriptSheet)

      newWindow.current.document
        .getElementsByTagName('head')[0]
        .appendChild(mediaCSS)

      newWindow.current.addEventListener('load', () => {
        window.postMessage(true, '*')
      })

      newWindow.current.addEventListener('beforeunload', () => {
        setData(false)
        window.postMessage(false, '*')
      })

      return () => {
        setData(false)
        if (newWindow.current) {
          newWindow.current.close()
        }
      }
    }
  }, [window])

  return newWindow.current && container
    ? createPortal(
        <main className={['width-full', 'bg-base-lightest'].join(' ')}>
          <section
            className={[
              'display-flex',
              'flex-gap',
              'flex-column',
              'grid-col',
              'margin-x-auto',
              'maxw-desktop-lg',
              'padding-x-3',
              'width-full',
            ].join(' ')}
          >
            <>
              <div
                className={[
                  'display-flex',
                  'flex-gap-lg',
                  'flex-column',
                  'grid-col',
                  'margin-bottom-4',
                  'margin-top-0',
                  'tablet:flex-row',
                  'tablet:grid-row',
                  'width-full',
                ].join(' ')}
              >
                <div
                  className={[
                    'display-flex',
                    'flex-gap-sm',
                    'flex-column',
                    'grid-col',
                  ].join(' ')}
                >
                  {
                    <>
                      <Header />
                      <div
                        className="link-msg"
                        dangerouslySetInnerHTML={{
                          __html: msg,
                        }}
                      ></div>
                      {children}
                      <a href="https://catalog.archives.gov/">
                        National Archives NextGen Catalog
                      </a>
                    </>
                  }
                </div>
              </div>
            </>
          </section>
        </main>,
        container
      )
    : null
}

PopupWindow.defaultProps = {}
PopupWindow.propTypes = {
  children: PropTypes.any,
  naids: PropTypes.array,
  setData: PropTypes.any,
  window: PropTypes.object.isRequired,
  setThumbs: PropTypes.bool.isRequired,
  msg: PropTypes.string.isRequired,
}

export default PopupWindow
