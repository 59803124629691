import React from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Settings
import { ColorPropTypes } from '../settings/ColorPropTypes'
//Components
import { Button } from '../Button/Button'
//Hooks
import useSearchInput from './use-search-input'

/**
 * Search allows users to search for specific content if they know what
 * search terms to use or can’t find desired content in the main navigation
 *
 * USWDS: https://designsystem.digital.gov/components/search/
 *
 */
const Search = ({
  className,
  context,
  isSearchWithin,
  filters,
  onChange,
  onSubmit,
  theme,
  ...props
}) => {
  const {
    handleOnChange,
    handleOnSubmit,
    searchQuery,
    value,
    searchWithinValue,
  } = useSearchInput(onSubmit, isSearchWithin)

  return (
    /**
     * USABILITY: Make the input at least 27 characters wide.
     * Allow the search component to be as wide as possible, but a minimum of 27 characters wide.
     * This allows users to enter multiple search terms and still be able to see all of them.
     * The more users can see their search terms, the easier it is to review, verify,
     * and submit their search query. Romance languages are about 20% longer than English,
     * so account for text swell on multilingual websites.
     */
    <form
      className={[
        'display-flex',
        'flex-grow-1',
        'flex-justify',
        'flex-no-wrap',
        'flex-row',
        'position-relative',
        'radius-md',
      ].join(' ')}
      data-testid="nac-basic-search--form"
      onSubmit={handleOnSubmit}
      role="search"
    >
      {filters && <div className={['search-left'].join(' ')}></div>}
      <div
        className={[
          'search-fill',
          //
          'display-flex',
          'flex-align-stretch',
          'flex-justify',
          'flex-row',
          'margin-y-05',
          'width-full',
        ].join(' ')}
      >
        {/**
         * USABILITY: Use a label even if it’s visually hidden.
         * The form field should include a label for screen reader users.
         */}
        <label htmlFor="search">
          {/**TO DO: Add label parameter */}
          <span className="usa-sr-only">Search</span>
        </label>
        <input
          data-testid="nac-basic-search--input"
          type="search"
          name="q"
          id="search"
          onChange={(e) => {
            sessionStorage.setItem('search', e.target.value)
            handleOnChange(e)
          }}
          value={
            isSearchWithin
              ? searchWithinValue
              : value
              ? value
              : searchQuery
              ? searchQuery
              : ''
          }
          className={[
            'usa-input',
            //
            'bg-white',
            // 'border-1px',
            // `border-${theme}`,
            'height-auto',
            'maxw-full',
            'padding-x-105',
            filters ? 'radius-left-0' : 'radius-left-md',
            'radius-right-0',
            'width-full',
          ].join(' ')}
          {...props}
        />
      </div>
      <div className={['search-right'].join(' ')}>
        {/**
         * USABILITY: The magnifying glass icon is effective.
         * The magnifying glass has been shown to be almost universally recognized
         * by users as an indicator of search, and doesn’t need to be visually paired
         * with the word “Search” as long as it remains for screen readers.
         */}
        <Button
          aria-label="Keyword Search"
          className={[
            'height-full',
            'margin-right-neg-05',
            'radius-right-md',
          ].join(' ')}
          data-testid="button-basic-search--submit"
          disabled={
            (isSearchWithin && searchWithinValue === '') ||
            (!isSearchWithin && value === '')
          }
          iconName="search"
          iconOnly
          reduced
          shape="0"
          /**
           * ACCESSIBILITY: Include the word “Search” in the button.
           * Always include the word “search” inside the <button> element for screen readers.
           * You can visually hide this text using the CSS class usa-sr-only or Sass mixin @include sr-only.
           */
          srText="Search"
          // theme={theme}
          /**
           * USABILITY: The search button should be a submit button.
           * This reduces the number of keystrokes required to use the form.
           */
          type="submit"
        />
      </div>
    </form>
  )
}

Search.defaultProps = {
  className: '',
  context: 'primary',
  filters: false,
  onSubmit: () => {},
  onChange: () => {},
  theme: 'primary',
}

Search.propTypes = {
  className: PropTypes.string,
  /**
   * Defines the specific search context
   */
  context: PropTypes.string,

  isSearchWithin: PropTypes.bool,

  /**
   * Placeholder attribute for future pre-filter search capabilities
   */
  filters: PropTypes.bool,
  /**
   * Function to run on submit
   */
  onSubmit: PropTypes.func,
  /**
   * Function to run on input value change
   */
  onChange: PropTypes.func,
  /**
   * Defines the color of the button. This is based on a subset of the USWDS color utilities (https://designsystem.digital.gov/utilities/color/)
   */
  theme: PropTypes.oneOf(ColorPropTypes),
}

export default Search
