import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../../styles/index.scss'
import DOMPurify from 'dompurify'
import {
  DEFAULT_CONTENTS_PANEL,
  DEFAULT_PAGE,
  DEFAULT_PANEL,
} from '../settings/globals'
import { PANEL_TYPES } from '../DigitalObjects/_object_settings'
import {
  audioTypes,
  imageTypes,
  videoTypes,
} from '../../helper-functions/metadata-functions'
import useUrlParams from '../../hooks/use-url-params'
import { returnFileFormat } from '../DigitalObjects/object_preview'

const useDescriptionParams = (loading, record) => {
  //Parameter Variables

  const { state } = useLocation()

  //States
  const [activeObjectPage, setActiveObjectPage] = useState(DEFAULT_PAGE)
  const [activeObjectType, setActiveObjectType] = useState('Object')
  const [activePanel, setActivePanel] = useState(DEFAULT_PANEL)
  const [contentsPanel, setContentsPanel] = useState(DEFAULT_CONTENTS_PANEL)
  const [filteredObjects, setFilteredObjects] = useState(null)
  const [objectId, setObjectId] = useState(null)

  const { removeParams, params, updateParams } = useUrlParams(state)

  // Create state to house meaningful values based on params
  const objectPage = params['objectPage'] || DEFAULT_PAGE
  // const objectId = filteredObjects
  //   ? parseInt(filteredObjects[activeObjectPage - 1]?.objectId)
  //   : null

  /* Ensure displayed objects do not include certain file types */
  useEffect(() => {
    if (!record) return false
    let displayObjects = record.digitalObjects
      ?.filter(
        ({ objectType, objectUrl }) =>
          objectUrl &&
          (imageTypes.some((element) => objectType?.includes(element)) ||
            videoTypes.some((element) => objectType?.includes(element)) ||
            audioTypes.some((element) => objectType?.includes(element)))
      )
      // .sort((a, b) => {})
      .sort((a, b) => {
        if (
          // a.objectDesignator ||
          returnFileFormat(b.objectFilename) == 'pdf' ||
          returnFileFormat(b.objectType) == 'pdf'
        )
          return -1
        return 1
      })
    setFilteredObjects(displayObjects)
  }, [record])

  /* When the display or active object changes, ensure the relevant thumbnail is in view. */
  useEffect(() => {
    if (!activeObjectPage) return false
    const active = document.getElementById(`object-thumb--${activeObjectPage}`)
    if (!active) return false
    //active.parentNode.scrollTop = active.offsetTop
    active.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'start',
    })
    handleSetActiveObjectType()
  }, [activeObjectPage])

  /* When the display or active object changes, ensure the relevant thumbnail is in view. */
  useEffect(() => {
    if (!activePanel) return false
  }, [activePanel])

  useEffect(() => {
    // updateParams({ objectPage: activeObjectPage, objectPanel: activePanel })
  }, [activeObjectPage, activePanel])

  const handleSetContentsPanel = (active) => {
    if (typeof active == 'boolean') setContentsPanel(active)
    else setContentsPanel(!contentsPanel)
  }

  /**
   * @param {Number}  index   Specific index within the digitalObjects array
   * @param {Boolean} push    If true, pushes this instance to the history object
   */
  const handleSetActiveObjectPage = (page, push = false) => {
    if (!filteredObjects) return false
    page = parseInt(DOMPurify.sanitize(page))

    // Validate string
    const validatedpage = page >= 1 && page <= filteredObjects.length ? page : 1
    setActiveObjectPage(validatedpage)
  }

  const handleSetActivePanel = (panel, push = false) => {
    // Validate string
    const validatedPanel = PANEL_TYPES.includes(DOMPurify.sanitize(panel))
      ? DOMPurify.sanitize(panel)
      : null

    // Update querystring
    if (validatedPanel === '' || !validatedPanel) {
      removeParams(['objectPanel'])
    } else {
      updateParams({ objectPanel: validatedPanel })
      setContentsPanel(false)
    }

    setActivePanel(validatedPanel)
  }

  const handleSetActiveObjectType = () => {
    if (!activeObjectPage || !filteredObjects)
      return setActiveObjectType('Object')
    else if (
      imageTypes.some((element) =>
        filteredObjects[activeObjectPage - 1]?.objectType?.includes(element)
      )
    )
      return setActiveObjectType('Image')
    else if (
      videoTypes.some((element) =>
        filteredObjects[activeObjectPage - 1]?.objectType?.includes(element)
      )
    )
      return setActiveObjectType('Video')
    else if (
      audioTypes.some((element) =>
        filteredObjects[activeObjectPage - 1]?.objectType?.includes(element)
      )
    )
      return setActiveObjectType('Audio File')
  }

  useEffect(() => {
    if (filteredObjects)
      setObjectId(parseInt(filteredObjects[activeObjectPage - 1]?.objectId))
  }, [filteredObjects, activeObjectPage])

  useEffect(() => {
    if (parseInt(params.objectPage) && params.objectPage !== 1)
      setActiveObjectPage(params.objectPage)
  }, [params])

  return {
    activeObjectPage,
    activeObjectType,
    contentsPanel,
    filteredObjects,
    handleSetActiveObjectPage,
    handleSetActivePanel,
    handleSetContentsPanel,
    objectId,
  }
}
export default useDescriptionParams
