import React from 'react'
import '../../styles/index.scss'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ColorPropTypes } from '../settings/ColorPropTypes'

/**
 * Logo displays the National Archives Catalog svg and text in the desired layout.
 */
const Logo = ({ layout, reduced, theme, ...props }) => {
  return (
    <Link
      aria-label="NAC Home"
      className={[
        'nac-logo',
        `nac-logo--${layout}`,
        theme ? `theme-${theme}` : '',
        //
        'height-full',
        `${
          layout == 'vertical'
            ? 'display-flex flex-column padding-x-2 flex-align-center'
            : 'display-flex flex-row'
        }`,
      ].join(' ')}
      data-testid={
        layout == 'vertical' ? 'nac-footer--logo' : 'nac-header--logo'
      }
      to="/"
      title="NAC Home"
    >
      <div
        className={[
          'nac-logo__icon',
          `${
            layout == 'vertical'
              ? 'display-flex flex-row margin-top-2 margin-bottom-1 square-card'
              : 'display-flex flex-column margin-right-1 margin-bottom-05 square-7'
          }`,
        ].join(' ')}
      ></div>
      {/** ACCESSIBILITY:
       *  If you’re using a logo that’s text, use an em, not an h1,...
       *  ...unless it’s the homepage.
       *  (http://csswizardry.com/2010/10/your-logo-is-an-image-not-a-h1/)
       *  */}
      {!reduced && (
        <em
          className={[
            'nac-logo__text',
            //
            'flex-column',
            'margin-right-2',
            'margin-top-1',
            `${layout == 'vertical' ? 'padding-x-2' : ''}`,
          ].join(' ')}
        >
          <span
            className={[
              'display-flex',
              'flex-row',
              'margin-bottom-05',
              'text-no-wrap',
            ].join(' ')}
          >
            National Archives
          </span>
          <span
            className={[
              'display-flex',
              'flex-row',
              'nac-logo__text--large',
            ].join(' ')}
          >
            Catalog
          </span>
        </em>
      )}
    </Link>
  )
}

Logo.defaultProps = {
  layout: 'horizontal',
  theme: 'primary-dark',
}

Logo.propTypes = {
  /**
   * Defines the orientation of the svg logo in relation to the text.
   */
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * When true, only the logo is visible and the site title is hidden
   */
  reduced: PropTypes.bool,
  /**
   * Defines the background color on which the logo will be displayed to ensure the logo color will be accessible. This is based on a subset of the USWDS color utilities (https://designsystem.digital.gov/utilities/color/)
   */
  theme: PropTypes.oneOf(ColorPropTypes),
}

export default Logo
