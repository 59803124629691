import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import '../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import SearchContext from '../contexts/SearchContext'
//Components
import Search from './Search/Search'

const CatalogSearch = ({ className, ...props }) => {
  const searchContext = useContext(SearchContext)
  const navigate = useNavigate()

  const handleSubmit = (event, value) => {
    event.preventDefault()

    let searchString = `?page=1&q=${encodeURIComponent(value)}`

    navigate({
      pathname: 'search',
      search: searchString,
    })
    searchContext.handleActiveQueryParams()
    searchContext.setActiveQueryParams({ q: value, page: 1 })
  }

  return <Search className={className} onSubmit={handleSubmit} />
}

CatalogSearch.propTypes = {
  className: PropTypes.string,
}
export default CatalogSearch
